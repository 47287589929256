import PropTypes from 'prop-types';

import resolve from 'core/resolver/resolve';

import withBreakpoint from 'core/components/breakpoint/withBreakpoint';
import withPageHocs from 'core/components/withPageHocs';

import ColumnLayout from 'core/components/ColumnLayout';
import Page from 'core/components/Page';
import Feed from 'core/components/Feed';
import {
  Indent,
  PageIndent,
} from 'core/components/Wrappers';

import ListHeader from 'site/components/ListHeader';
import InnerSideColumn from 'site/components/InnerSideColumn';
import Boroda from 'site/components/Boroda';
import { PageBoroda } from 'site/components/Wrappers';

import footerFetcher from 'site/fetchers/footer';

import { CardVerticalL } from 'site/cards/CardVertical';

import { VERTICAL_INDENT, VERTICAL_INDENT_MOBILE } from 'site/constants';


const PAGE_TITLE = 'Спецпроекты';

CardVerticalL.requiredPayloadImports = ['image'];

function Special({ footerContent, isMobile }) {
  const vertical = isMobile ? VERTICAL_INDENT_MOBILE : VERTICAL_INDENT;

  return (
    <PageBoroda content={footerContent}>
      <Page title={PAGE_TITLE}>
        <PageIndent>
          <ColumnLayout rightColumn={<InnerSideColumn />}>
            <ListHeader>{PAGE_TITLE}</ListHeader>
            <Indent top={vertical} />
            <Feed
              grid
              topic_type='specproject'
              card={CardVerticalL}
              columns={2}
              interitemSpacing={50}
            />
          </ColumnLayout>
          <Boroda />
        </PageIndent>
      </Page>
    </PageBoroda>
  );
}

Special.propTypes = {
  footerContent: PropTypes.array,
  isMobile: PropTypes.bool,
};

const dataProvider = resolve({
  footerContent: footerFetcher(),
});

export default withPageHocs(dataProvider)(withBreakpoint(Special));
