import { withBreakpoint } from 'core/components/breakpoint';
import Exchange from 'core/components/Exchange';

export { default as Recommender } from './Recommender';


export function PartnersVertical() {
  return (
    <Exchange
      pageType='desktop_not_main'
      blockType='vertical'
    />
  );
}

export const PartnersHorizontal = withBreakpoint(({ breakpoint }) => {
  return (
    <Exchange
      pageType={`${breakpoint}_topics`}
      blockType='horizontal'
    />
  );
});
