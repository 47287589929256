import PropTypes from 'prop-types';
import { Fragment } from 'react';

import withTheme from 'core/components/theme';
import withBreakpoint from 'core/components/breakpoint/withBreakpoint';

import Divider from 'core/components/Divider';
import Social from 'core/components/Social';

import Link from 'core/components/Link';

import modelPropTypes, { personAttributes } from 'core/utils/prop-types/model';

import { CardAuthorVertical, CardAuthorTopLabel } from 'site/cards/CardAuthor';
import Indent from 'site/components/Wrappers/Indent';

import ArrowStickLeft from 'site/icons/ArrowStickLeft.svg';

import styles from './index.styl';


function AuthorPageCard(props) {
  const {
    author,
    allAuthorsLink,
    isMobile,
    isDesktop,
    theme,
  } = props;

  const {
    biography,
    social_networks: socialNetworks,
    email,
  } = author.attributes;

  const haveSocialNetworks = socialNetworks && socialNetworks.length > 0;
  const AuthorCard = isMobile ? CardAuthorVertical : CardAuthorTopLabel;

  return (
    <Fragment>
      <style jsx>{`
        .authors
          font 700 16px/19px ${theme.fonts.display}
        .${styles.email}
          font 14px/17px ${theme.fonts.display}
      `}</style>
      {allAuthorsLink && (
        <div className={styles.allAuthorsLink}>
          <Link
            type='primary'
            to={allAuthorsLink}
          >
            <ArrowStickLeft />
            <span className='authors'>Все авторы</span>
          </Link>
        </div>
      )}
      <AuthorCard author={author} />
      {!!email && <Link
        to={`mailto:${email}`}
        type='primary'
      >
        <span className={styles.email}>{email}</span>
      </Link>}
      {(!!biography || haveSocialNetworks) &&
        <Fragment>
          {isDesktop &&
            <Indent top={30} bottom={haveSocialNetworks ? 7 : 15}>
              <Divider />
            </Indent>
          }
          {isMobile && <Indent top={10} />}
          {haveSocialNetworks &&
            <Social socials={socialNetworks} />
          }
          {isMobile && <Indent top={10} />}
          {biography}
          <Indent top={isMobile ? 10 : 15} bottom={isMobile ? 15 : 30}>
            <Divider />
          </Indent>
        </Fragment>
      }
    </Fragment>
  );
}

AuthorPageCard.propTypes = {
  theme: PropTypes.object,
  isMobile: PropTypes.bool,
  isDesktop: PropTypes.bool,
  /** Данные автора, соответствующие модели `person` */
  author: modelPropTypes(personAttributes),
  /** Ссылка для перехода ко всем авторам */
  allAuthorsLink: PropTypes.string,
};

export default withBreakpoint(withTheme(AuthorPageCard));
