import color from 'core/libs/color';
import baseColors from './colors';

const colors = {
  ...baseColors,
  primary: baseColors.text, // Основной цвет, текст
  layout: '#fff', // цвет фона body
  content: '#fff', // цвет фона контентной области
  active1: baseColors.text, // Доп. цвет, выделение, ссылки
  get placeholder() {
    return color(this.primary).alpha(0.3).string();
  },
  get theme() { // цвет темы, meta theme-color, tile-color, safari-pinned-tab
    return this.active1;
  },
};

const skeleton = {
  background: color(colors.primary).alpha(0.1).string(),
};

const link = {
  tertiary: {
    hover: {
      color: colors.primary,
      decoration: 'underline',
    },

    active: {
      color: colors.primary,
    },

    visited: {
      color: colors.primary,
    },
  },
};

const paginatron = {
  text: {
    color: colors.text,
  },
};

export default {
  colors,
  controls: {
    link,
    paginatron,
    skeleton,
  },
};
