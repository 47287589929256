import PropTypes from 'prop-types';

import resolve from 'core/resolver/resolve';

import CoreProfile from 'core/components/ugc/Profile';
import withPageHocs from 'core/components/withPageHocs';

import footerFetcher from 'site/fetchers/footer';

import { PageBoroda } from 'site/components/Wrappers';


function Profile({ footerContent }) {
  return (
    <PageBoroda content={footerContent}>
      <CoreProfile />
    </PageBoroda>
  );
}

Profile.propTypes = {
  footerContent: PropTypes.array,
};

const dataProvider = resolve({
  footerContent: footerFetcher(),
});

export default withPageHocs(dataProvider)(Profile);
