import PropTypes from 'prop-types';

import { CONTENT_TEXT_AREA, TOPIC_INDENT_LEFT } from 'site/constants';


export default function TopicTextWrap(props) {
  const {
    children,
    invert,
    isPremiumTopic,
  } = props;

  const sign = invert ? -1 : 1;

  return (
    <div className='topic-text-wrap' data-qa='topic-text-wrap'>
      <style jsx>{`
        .topic-text-wrap
          max-width ${invert ? 'none' : CONTENT_TEXT_AREA + 'px'}
          margin ${isPremiumTopic ? '0 auto' : `0 0 0 ${sign * TOPIC_INDENT_LEFT}px`}
          @media screen and (max-width: 1200px)
            margin-left ${isPremiumTopic ? 'auto' : `${sign * 5}vw`}
        `}
      </style>
      {children}
    </div>
  );
}

TopicTextWrap.propTypes = {
  /**
   * Те же отступы, но с отрицательными значениями.
   * Также отключает max-width.
   */
  invert: PropTypes.bool,
  isPremiumTopic: PropTypes.bool,
};
