import PropTypes from 'prop-types';
import withBreakpoint from 'core/components/breakpoint/withBreakpoint';
import Indent from './Indent';

import { SIDE_INDENT_MOBILE } from 'site/constants';

function NegativeMobile(props) {
  const {
    children,
    isMobile,
  } = props;

  if (isMobile) {
    return (
      <Indent left={-SIDE_INDENT_MOBILE} right={-SIDE_INDENT_MOBILE}>
        {children}
      </Indent>
    );
  }

  return children;
}

NegativeMobile.propTypes = {
  isMobile: PropTypes.bool,
};

export default withBreakpoint(NegativeMobile);
