import { nest, compose } from 'core/libs/recompose';

import bindProps from 'core/components/bindProps';

import Ad from 'core/components/Ad';

import { NegativeMobile } from 'site/components/Wrappers';
import inPageStyles from './styles/inPage';
import vertical from './styles/vertical';

import sponsoredStyles from 'core/components/Ad/styles/sponsoredStyles';

/**
 * У мобильных баннеров нужно указывать мобильный `siteId` (`begun-auto-pad`).
 */
const MobileAd = bindProps({
  siteId: '459973582',
  height: 250,
  hideOnPreview: true,
})(Ad);

const listingSpecProps = bindProps({
  name: 'Listing Spec (COM)',
  lazy: true,
  blockId: '459973954',
  hideOnPreview: true,
  options: {
    p1: 'bxuur',
    p2: 'fqbd',
    pct: 'a',
  },
});

export const TopBanner = bindProps({
  name: 'TopBanner',
  blockId: '459973718',
  hideOnPreview: true,
  options: {
    p1: 'bswnc',
    p2: 'fexc',
    pct: 'a',
  },
})(MobileAd);

export const Fullscreen = bindProps({
  name: 'Fullscreen',
  height: null,
  blockId: '459974426',
  hideOnPreview: true,
  options: {
    p1: 'bswnb',
    p2: 'etsa',
    pct: 'a',
  },
})(MobileAd);

export const Rich = bindProps({
  name: 'Rich',
  height: null,
  blockId: '459973796',
  hideOnPreview: true,
  options: {
    p1: 'btgyw',
    p2: 'ffhi',
    pct: 'a',
  },
})(MobileAd);

export const Listing1 = bindProps({
  name: 'Listing1 (COM)',
  lazy: true,
  blockId: '459974324',
  hideOnPreview: true,
  options: {
    p1: 'bwzfo',
    p2: 'emil',
    pct: 'a',
  },
})(nest(NegativeMobile, MobileAd));

export const Listing2 = bindProps({
  name: 'Listing2 (COM)',
  lazy: true,
  blockId: '459974322',
  hideOnPreview: true,
  options: {
    p1: 'bwzfk',
    p2: 'emil',
    pct: 'a',
  },
})(nest(NegativeMobile, MobileAd));

export const Listing3 = bindProps({
  name: 'Listing3 (COM)',
  lazy: true,
  blockId: '459974320',
  hideOnPreview: true,
  options: {
    p1: 'bwzfn',
    p2: 'emil',
    pct: 'a',
  },
})(nest(NegativeMobile, MobileAd));

export const Listing4 = bindProps({
  name: 'Listing4 (COM)',
  lazy: true,
  blockId: '459974124',
  hideOnPreview: true,
  options: {
    p1: 'bwzfm',
    p2: 'emil',
    pct: 'a',
  },
})(nest(NegativeMobile, MobileAd));

export const ListingSpec = compose(
  listingSpecProps,
  vertical,
)(MobileAd);

export const Footer = bindProps({
  name: 'Footer (COM)',
  lazy: true,
  blockId: '459974432',
  hideOnPreview: true,
  options: {
    p1: 'bwzfq',
    p2: 'emil',
    pct: 'a',
  },
})(MobileAd);

export const Content1 = bindProps({
  name: 'Content1 (COM)',
  lazy: true,
  blockId: '459974540',
  hideOnPreview: true,
  options: {
    p1: 'bwzfr',
    p2: 'emil',
    pct: 'a',
  },
})(nest(NegativeMobile, MobileAd));

export const Content2 = bindProps({
  name: 'Content2 (COM)',
  lazy: true,
  blockId: '459974538',
  hideOnPreview: true,
  options: {
    p1: 'bwzfp',
    p2: 'emil',
    pct: 'a',
  },
})(nest(NegativeMobile, MobileAd));

export const Content3 = bindProps({
  name: 'Content3 (COM)',
  lazy: true,
  blockId: '459974534',
  hideOnPreview: true,
  options: {
    p1: 'bwzfl',
    p2: 'emil',
    pct: 'a',
  },
})(nest(NegativeMobile, MobileAd));

export const Content4 = bindProps({
  name: 'Content4 (COM)',
  lazy: true,
  blockId: '459974532',
  hideOnPreview: true,
  options: {
    p1: 'bwzft',
    p2: 'emil',
    pct: 'a',
  },
})(nest(NegativeMobile, MobileAd));

export const ContentSpec = compose(
  bindProps({
    name: 'Content Spec (COM)',
    lazy: true,
    blockId: '459974526',
    hideOnPreview: true,
    options: {
      p1: 'bxuus',
      p2: 'fqbd',
      pct: 'a',
    },
  }),
  inPageStyles,
)(MobileAd);

export const InPage = bindProps({
  name: 'InPage',
  height: 200,
  blockId: '459974416',
  hideOnPreview: true,
  options: {
    p1: 'bswnd',
    p2: 'fexd',
    pct: 'a',
  },
})(nest(NegativeMobile, MobileAd));

export const Sponsored = compose(
  bindProps({
    name: 'Sponsored (COM)',
    siteId: '459973582',
    blockId: '459973738',
    hideOnPreview: true,
    options: {
      p1: 'bwzfs',
      p2: 'fooq',
      pct: 'c',
    },
  }),
  sponsoredStyles,
)(Ad);

export const InGallery = bindProps({
  name: 'InGallery',
  blockId: '579389982',
  disableAutoInit: true,
  height: null,
  hideOnPreview: true,
})(MobileAd);
