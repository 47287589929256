import PropTypes from 'prop-types';

import { withRouter } from 'core/libs/router';

import { isGameCompare } from 'core/components/GameCompare/utils';

import { withBreakpoint } from 'core/components/breakpoint';
import ColumnLayout from 'core/components/ColumnLayout';
import EmptyWrapper from 'core/components/EmptyWrapper';
import { Indent } from 'core/components/Wrappers';

import TopicToplineSkeleton from 'core/components/TopicTopline/Skeleton';
import TopicHeaderTextsSkeleton from 'core/components/TopicHeaderTexts/Skeleton';
import GameCompareSkeleton from 'core/components/GameCompare/Skeleton';

import InnerSideColumn from 'site/components/InnerSideColumn';
import { PageIndent, TopicTextWrap } from 'site/components/Wrappers';

import { isEnciklopediya } from 'site/utils.js';
import { ENCIKLOPEDIYA_PATHNAME } from 'site/constants.js';

import Common from './Common';


function getTopicType(pathname) {
  if (isGameCompare(pathname)) {
    return 'gameCompare';
  }

  return 'default';
}

const loadersMap = {
  gameCompare: GameCompareSkeleton,
  default: Common,
};

function Skeleton({ isMobile, location }) {
  const Content = loadersMap[getTopicType(location.pathname)];
  const WideWrapper = isMobile ? EmptyWrapper : TopicTextWrap;

  return (
    <PageIndent>
      <ColumnLayout rightColumn={<InnerSideColumn hideRnet={isEnciklopediya(location.pathname, ENCIKLOPEDIYA_PATHNAME)} />}>
        <WideWrapper>
          <TopicToplineSkeleton height={isMobile ? 22 : 26} />
          <Indent top={isMobile ? 20 : 15} />
          <TopicHeaderTextsSkeleton
            titleHeight={isMobile ? 50 : 80}
            leadHeight={isMobile ? 100 : 150}
          />
          <Indent top={isMobile ? 10 : 15} />
        </WideWrapper>
        <Content />
      </ColumnLayout>
    </PageIndent>
  );
}

Skeleton.propTypes = {
  isMobile: PropTypes.bool,
  location: PropTypes.object,
};

export default withRouter(withBreakpoint(Skeleton));

