import PropTypes from 'prop-types';

import withTheme from 'core/components/theme';
import withBreakpoint from 'core/components/breakpoint/withBreakpoint';

import styles from './index.styl';

function HoverFrame({ triggerClassName, children, theme, isMobile }) {
  if (isMobile) return children;

  const rootClassName = triggerClassName || styles.frameWrapper;

  return (
    <div className={styles.frameWrapper}>
      <style jsx>{`
        .${styles.rightBorder}
          background-color ${theme.colors.primary}
          &:after
            border-color ${theme.colors.content}
        :global(.${rootClassName}:hover)
        :global(.${rootClassName}:hover) .${styles.frameWrapper}
        .${styles.frameWrapper}:hover
          border-radius 5px
          overflow hidden
        :global(.${rootClassName}:hover)
          .${styles.frame}
            border-color ${theme.colors.primary}
          .${styles.rightBorder}
            width 8px
      `}</style>
      <div className={styles.frame} />
      <div className={styles.rightBorder} />
      {children}
    </div>
  );
}

HoverFrame.propTypes = {
  /**
   * Класс элемента, по ховеру на который должна появляться рамка.
   * Можно передать обычный, не-scoped класс.
   * Если не передать этот параметр, рамка появится по ховеру на
   * этот компонент.
   */
  triggerClassName: PropTypes.string,

  /** @ignore */
  theme: PropTypes.object,

  /** @ignore */
  isMobile: PropTypes.bool,
};

const HoverFrameWithHOCs = withBreakpoint(withTheme(HoverFrame));

HoverFrameWithHOCs.displayName = 'HoverFrame';

export default HoverFrameWithHOCs;

export { HoverFrame as StorybookComponent };
