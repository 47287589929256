const letterRegex = /([A-z]|[А-я]|\d)/;

export default function groupTopicsByFirstLetter(data, injections) {
  if (!Array.isArray(data)) throw new Error('data given to groupTopicsByFirstLetter is not an Array');

  return data.reduce((result, topic, i) => {
    const {
      attributes: {
        list_headline: listHeadline,
        headline,
      } = {},
    } = topic;
    const title = listHeadline || headline;
    const firstLetterIndex = title.search(letterRegex);
    const firstLetter = firstLetterIndex === -1
      ? ''
      : title[firstLetterIndex];

    if (!result.has(firstLetter)) result.set(firstLetter, {});

    const currentLetterData = result.get(firstLetter);

    Array.isArray(currentLetterData.topics)
      ? currentLetterData.topics.push(topic)
      : currentLetterData.topics = [topic];
    if (injections[i]) {
      currentLetterData.injections
        ? currentLetterData.injections[currentLetterData.topics.length - 1] = injections[i]
        : currentLetterData.injections = { [currentLetterData.topics.length - 1]: injections[i] };
    }
    return result;
  }, new Map);
}
