export default {
  text: '#302E28', // Основной цвет, текст
  yellow: '#F7D985',
  error: '#FF0000', // ошибки
  divider: '#EAEAEA', // рамки, разделители
  divider2: '#161716', // разделитель в виджете Number
  hint: '#5E5E5E', // Подписи к тексту, рубрики, доп. информация
  hintLight: '#BABABA', // Подписи к тексту, рубрики, доп. информация
  grey: '#323232', // серая подложка на главной
  grey2: '#F5F5F5', // бэкграунд карточек
  grey3: '#979797', // должность автора
  grey4: '#7D7D7D', // рамка на стр энциклопедии
  grey5: '#444444', // скобки на цифрах в виджете Цифра
  number: '#3F4140', // цвет Цифр в соответствующем виджете
  white: '#FFFFFF', // общий
  black: '#000000', // общий
  accent: '#FBFF23', // Выделение UGC
  oceanBlue: '#4A7A91',
};
