import PropTypes from 'prop-types';

import withTheme from 'core/components/theme';

import styles from './index.styl';


function HighlightedText(props) {
  const {
    children,
    theme,
    color,
  } = props;

  const highlightColor = color || theme.colors.accent;

  return (
    <div className={styles.container}>
      <style jsx>{`
        .${styles.container} > :global(*)
          background-color ${highlightColor}
          box-shadow: 0.2em 0 0 ${highlightColor}, -0.2em 0 0 ${highlightColor};
        `}</style>
      {children}
    </div>
  );
}

HighlightedText.propTypes = {
  /** @ignore */
  theme: PropTypes.object,
  /** @ignore */
  children: PropTypes.node,
  /** Цвет, переопределяющий дефолтный из темы */
  color: PropTypes.string,
};

const HighlightedTextWithHOCs = withTheme(HighlightedText);

HighlightedTextWithHOCs.displayName = 'HighlightedText';

export default HighlightedTextWithHOCs;

export { HighlightedText as StorybookComponent };
