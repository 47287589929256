import ThemeSwitch from 'core/components/ThemeSwitch';
import ContentBackground from 'core/components/ContentBackground';
import PropTypes from 'prop-types';


function Themer({ children, themeName }) {
  return (
    <ThemeSwitch name={themeName}>
      <ContentBackground>
        {children}
      </ContentBackground>
    </ThemeSwitch>
  );
}
Themer.propTypes = {
  /** Имя темы */
  themeName: PropTypes.string,
};

export default Themer;
