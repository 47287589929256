import { Fragment } from 'react';
import PropTypes from 'prop-types';

import resolve from 'core/resolver/resolve';

import { denormalizeData } from 'core/utils/api';
import modelPropTypes, {
  topicAttributes,
  listAttributes,
} from 'core/utils/prop-types/model';

import { Desktop, Mobile } from 'core/components/breakpoint';
import withBreakpoint from 'core/components/breakpoint/withBreakpoint';
import withPageHocs from 'core/components/withPageHocs';

import EmptyWrapper from 'core/components/EmptyWrapper';
import Page from 'core/components/Page';
import AdWrapper from 'core/components/Ad/AdWrapper';
import StickyPortal from 'core/components/StickyPortal';
import ColumnLayout from 'core/components/ColumnLayout';

import {
  Ad240x400,
  Ad240x400Second,
  SuperFooter,
  Parallax,
  Native1AsCardVertical,
  Native2AsCardVertical,
} from 'site/components/Ads/desktop';

import {
  Listing1,
  Listing2,
} from 'site/components/Ads/mobile';

import FancyBlock from 'site/components/FancyBlock';
import { Recommender } from 'site/components/Partners';
import { PageBoroda } from 'site/components/Wrappers';
import {
  PageIndent,
  Indent,
  NegativeMobile,
} from 'core/components/Wrappers';
import SelfPromo from 'core/components/SelfPromo';

import DarkTop from './DarkTop';
import OnePlusThree from './OnePlusThree';
import News from './News';
import UserStories from './UserStories';
import Faded from './Faded';

import {
  VERTICAL_INDENT,
  VERTICAL_INDENT_MOBILE,
} from 'site/constants';

import footerFetcher from 'site/fetchers/footer';


const TOPIC_FIELDS = 'headline,list_headline,alternative_headline,published_at,link,topic_type,ad_label,link_with_announce_erid,advertiser_name';
const RECEIVE_ERROR_PREFIX = '[Main page dataProvider] Error occured while receiving data for';
const LISTS_CONFIG = {
  main: {
    limit: 9,
    include: 'rubric,tags',
    fields: 'headline,list_headline,published_at,link,topic_type,ad_label',
  },
  themeoftheday1: {
    limit: 6,
    include: 'rubric,image',
    fields: TOPIC_FIELDS,
  },
  themeoftheday2: {
    limit: 4,
    include: 'rubric,image',
    fields: 'headline,published_at,link',
  },
  ugc: {
    autocompleted_list_limit: 7,
    include: 'rubric,image',
    fields: TOPIC_FIELDS,
    visibility: 'main_page',
    sort: 'list',
  },
};


function MainPage(props) {
  const {
    isMobile,
    content: {
      lists,
      news,
      articles,
    },
    themeOfTheDay1Data: {
      attributes: {
        title: theme1Title,
        subtitle: theme1Subtitle,
      },
    },
    footerContent,
  } = props;

  const vertical = isMobile ? VERTICAL_INDENT_MOBILE : VERTICAL_INDENT;
  const FancyStyler = isMobile ? EmptyWrapper : FancyBlock;

  return (
    <PageBoroda content={footerContent}>
      <Page>
        <PageIndent>
          <NegativeMobile>
            <DarkTop content={lists.main} />
          </NegativeMobile>
          <Indent bottom={vertical} />
          <ColumnLayout
            rightColumn={(
              <StickyPortal listenStickyRemoveEvent>
                <AdWrapper bottom={vertical}>
                  <Ad240x400 />
                  <SelfPromo />
                  <Indent bottom={vertical} />
                  <Ad240x400Second />
                </AdWrapper>
              </StickyPortal>
            )}
          >
            <AdWrapper bottom={vertical}>
              <Mobile>
                <Listing1 />
              </Mobile>
              <FancyStyler>
                <OnePlusThree
                  title={theme1Title || 'Картина дня'}
                  {...!isMobile && {
                    subtitle: theme1Subtitle,
                  }}
                  content={lists.themeoftheday1}
                  native={Native1AsCardVertical}
                />
              </FancyStyler>
              <Indent bottom={vertical} />
              <Desktop>
                <SuperFooter />
              </Desktop>
              <News content={news} />
              <Indent bottom={isMobile ? vertical : 40} />
              <Mobile>
                <Listing2 />
              </Mobile>
              <NegativeMobile>
                <FancyStyler top='-30px'>
                  <Faded content={lists.themeoftheday2} />
                </FancyStyler>
              </NegativeMobile>
              <Indent bottom={vertical} />
              <OnePlusThree
                content={articles}
                native={Native2AsCardVertical}
                anotherHero
              />
              <Indent bottom={vertical} />
            </AdWrapper>
          </ColumnLayout>
          <Desktop>
            <AdWrapper bottom={vertical}>
              <Parallax />
            </AdWrapper>
          </Desktop>
          <ColumnLayout rightColumn={<Fragment />}>
            <UserStories content={lists.ugc} />
          </ColumnLayout>
          <Recommender />
          <Indent bottom={vertical} />
        </PageIndent>
      </Page>
    </PageBoroda>
  );
}

const topicModel = modelPropTypes(topicAttributes);

MainPage.propTypes = {
  isMobile: PropTypes.bool,
  content: PropTypes.exact({
    lists: PropTypes.objectOf(PropTypes.arrayOf(topicModel)),
    news: PropTypes.arrayOf(topicModel),
    articles: PropTypes.arrayOf(topicModel),
  }),
  themeOfTheDay1Data: modelPropTypes(listAttributes),
  footerContent: PropTypes.array,
};

const dataProvider = resolve({
  content: async ({ content, bebopApi, renderOffline }) => { // eslint-disable-line
    try {
      if (content) return content;

      const idsToExclude = [];
      const listNames = Object.keys(LISTS_CONFIG);
      const createRequestFromList = list => bebopApi
        .getTopics({
          list,
          sort: 'list',
          ...LISTS_CONFIG[list],
        })
        .then(denormalizeData)
        .catch(() => {
          console.warn(`${RECEIVE_ERROR_PREFIX} '${list}' list`);
        });
      const getSettledValue = settledResults => settledResults.map(({ value }) => value);

      const topicsByLists = await Promise
        .allSettled(listNames.map(createRequestFromList))
        .then(getSettledValue);

      const listsData = topicsByLists
        .reduce((result, listTopics, i) => {
          const listName = listNames[i];
          // заодно сразу заполним idsToExclude [kinda facepalm]
          listTopics?.forEach(({ id }) => idsToExclude.push(id));
          result[listName] = listTopics;
          return result;
        }, {});

      const [
        news,
        articles,
      ] = await Promise
        .allSettled([
          bebopApi
            .getTopics({
              topic_type: 'news',
              sort: '-published_at',
              limit: 7,
              include: 'image,rubric',
              fields: TOPIC_FIELDS,
              excluded_ids: idsToExclude,
            })
            .then(denormalizeData)
            .catch(() => console.warn(`${RECEIVE_ERROR_PREFIX} news block`)),
          bebopApi
            .getTopics({
              topic_type: 'article,gallery',
              sort: '-published_at',
              limit: 4,
              include: 'image,rubric',
              fields: TOPIC_FIELDS,
              excluded_ids: idsToExclude,
            })
            .then(denormalizeData)
            .catch(() => console.warn(`${RECEIVE_ERROR_PREFIX} articles block`)),
        ])
        .then(getSettledValue);

      return { news, articles, lists: listsData };
    } catch (e) {
      renderOffline(e);
    }
  },

  themeOfTheDay1Data: ({ bebopApi, themeOfTheDay1Data }) => {
    return themeOfTheDay1Data || bebopApi
      .getList({
        list_slug: 'themeoftheday1',
      })
      .then(denormalizeData);
  },

  footerContent: footerFetcher(),
});

export default withPageHocs(dataProvider)(withBreakpoint(MainPage));
