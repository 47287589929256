import PropTypes from 'prop-types';
import cx from 'classnames';
import Link from 'core/components/Link';

import modelPropTypes, { personAttributes } from 'core/utils/prop-types/model';

import skip from 'core/resolver/skip';
import withTheme from 'core/components/theme';
import Avatar from 'core/components/Avatar';

import { getImageSrc } from 'site/utils.js';

import styles from './index.styl';


function AuthorOpinionHeader(props) {
  const {
    author,
    theme,
    vertical,
    disableLine,
  } = props;

  if (!author) return null;

  const {
    name,
    avatar: avatarAttrs,
    job_title: jobTitle,
    is_external: isExternal,
    slug,
  } = author.attributes;

  const avatarSrc = getImageSrc(avatarAttrs);

  return (
    <div
      className={cx(
        styles.container,
        vertical && styles._vertical,
        (vertical || disableLine) && '_withoutLine',
      )}
    >
      <style jsx>{`
        .name
          font 700 16px/19px ${theme.fonts.display}

        .${styles.job}
          font 12px/14px ${theme.fonts.display}
          color ${theme.colors.hint}

        .${styles.line}
          color ${theme.colors.hint}

        .${styles.avatar}
          border-bottom 1px solid ${theme.colors.hint}
          ._withoutLine  &
            border-bottom none
      `}</style>
      <div className={styles.avatar}>
        <Avatar
          isCircular
          src={avatarSrc}
          size={60}
        />
      </div>
      <div className={styles.body}>
        <div className={styles.info}>
          <div className={cx(styles.name, 'name')}>
            {isExternal ? name : (
              <Link className={styles.authorLink} to={`/authors/${slug}`}>{name}</Link>
            )}
          </div>
          {jobTitle && <div className={styles.job}>{jobTitle}</div>}
        </div>
        {!disableLine &&
          <div className={styles.line}>
            <div className={styles.corner} />
          </div>
        }
      </div>
    </div>
  );
}

AuthorOpinionHeader.propTypes = {
  /**
   * Данные автора, соответствующие модели `person` swagger-схемы.
   */
  author: modelPropTypes(personAttributes).isRequired,
  /** @ignore */
  theme: PropTypes.object,
  /**
   * Флаг, определяющий горизонтальное центрирование выноски для вертикального блока
   */
  vertical: PropTypes.bool,
  /**
   * Флаг отображения/скрытия выноски-разделителя
   */
  disableLine: PropTypes.bool,
};

const AuthorOpinionHeaderWithHOCs = skip(withTheme(AuthorOpinionHeader));

AuthorOpinionHeaderWithHOCs.displayName = 'AuthorOpinionHeader';

export default AuthorOpinionHeaderWithHOCs;

export { AuthorOpinionHeader as StorybookComponent };
