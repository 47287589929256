export default {
  0: {},
  1: {
    accented: true,
  },
  2: {
    accented: true,
    imageAtRight: true,
  },
};
