import PropTypes from 'prop-types';
import { Fragment } from 'react';

import Feed from 'core/components/Feed';
import CompareCard from 'site/cards/CompareCard';
import { Indent } from 'core/components/Wrappers';


function GameCompareRubric({ topics, isDesktop }) {
  return (
    <Fragment>
      {topics.length > 0 && (
        <Indent top={20}>
          <Feed
            content={topics}
            card={CompareCard}
            interitemSpacing={isDesktop ? 30 : 20}
          />
        </Indent>
      )}
    </Fragment>
  );
}

GameCompareRubric.propTypes = {
  topics: PropTypes.array,
  isDesktop: PropTypes.bool,
};

export default GameCompareRubric;
