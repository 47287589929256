import PropTypes from 'prop-types';
import cx from 'classnames';

import withTheme from 'core/components/theme';
import skip from 'core/resolver/skip';
import ThemeSwitch from 'core/components/ThemeSwitch';
import Button from 'core/components/Button';
import Link from 'core/components/Link';
import SmartImage from 'core/components/SmartImage';
import ContentBackground from 'core/components/ContentBackground';

import ArrowRightBold from 'site/icons/ArrowRightBold';

import UgcLabel from 'site/components/UgcLabel';

import img from './img/marker.png';

import styles from './index.styl';


function UgcPromo(props) {
  const {
    size,
    title,
    footer,
    buttonText,
    button,
    compactButton,
    theme,
    body,
  } = props;

  const small = size === 's';

  const className = small ? styles.medium : styles.large;

  const {
    ugcPromo: promoAtoms,
    button: {
      primary: buttonAtoms,
    },
  } = theme.controls;
  const showButton = !!(button || compactButton);

  const buttonMarkup = compactButton
    ? (
      <div className={styles.compactBtn}>
        <Button round>
          <ArrowRightBold
            height={14}
            width={14}
            style={{
              padding: '13px 12px 13px 14px',
            }}
          />
        </Button>
      </div>
    )
    : (
      <Button className={styles.btn}>
        <span className={styles.btnText}>{buttonText}</span>
      </Button>
    );

  return (
    <Link to='/profile/topic/new' type='secondary'>
      <div
        className={cx(
          styles.container,
          styles['_size_' + size],
        )}
      >
        <style jsx>{`
          .${styles.container}
            background ${promoAtoms.background}

          .${styles.title}
            font-family ${promoAtoms.title.font}
            color ${promoAtoms.title.color}

          .${styles.body}
            color ${promoAtoms.body.color}
            font-family ${promoAtoms.body.font}

          .${styles.compactBtn}
            background ${buttonAtoms.idle.background}
            color ${buttonAtoms.idle.color}

          .${styles.highlight}
            color ${theme.colors.accent}
        `}</style>
        <div className={styles.topWrapper}>
          <div className={styles.user}>
            <UgcLabel flag className={className} />
          </div>
          <div className={styles.textsWrapper}>
            <div className={styles.title}>
              {title || <div>Напишите&nbsp;
                <div className={styles.highlight}>свою историю&nbsp;
                  <div className={styles.marker}>
                    <SmartImage
                      url={img}
                      placement='contain'
                      position='top center'
                      width={small ? 24 : 30}
                      height={small ? 24 : 30}
                    />
                  </div>
                </div>
                <br />
                на Секрет Фирмы
              </div>
              }
            </div>
            {!small && !!body && (
              <div className={styles.body}>
                {body}
              </div>
            )}
          </div>
          {showButton && !footer &&
            <ThemeSwitch name='dark'>
              {buttonMarkup}
            </ThemeSwitch>
          }
          {small && !!body && (
            <div className={styles.body}>
              {body}
            </div>
          )}
        </div>
        {!!footer &&
          <div className={styles.footerWrapper}>
            <ContentBackground>
              <div className={styles.footer}>
                <div className={styles.footerText}>{footer}</div>
                {showButton && buttonMarkup}
              </div>
            </ContentBackground>
          </div>
        }
      </div>
    </Link>
  );
}

UgcPromo.propTypes = {
  /**
   * Элемент, содержащий заголовок промо-блока
   */
  title: PropTypes.node,

  /**
   * Элемент футера промо-блока
   */
  footer: PropTypes.node,

  /**
   * Элемент, содержащий контент промо-блока
   */
  body: PropTypes.node,

  /**
   * Текст на кнопке в футере.
   * Отображается, если указан параметр `button`
   */
  buttonText: PropTypes.string,

  /**
   * Размер промо-блока
   */
  size: PropTypes.oneOf(['s', 'm', 'l']),

  /**
   * Отображение кнопки "написать".
   * Если указан footer, будет отображаться рядом с ним.
   */
  button: PropTypes.bool,

  /**
   * Отображение круглой кнопки со стрелкой.
   * Имеет приоритет над параметром `button`.
   */
  compactButton: PropTypes.bool,

  /** @ignore */
  theme: PropTypes.object,
};

UgcPromo.defaultProps = {
  size: 'm',
  buttonText: 'Написать',
  body: null,
};

const UgcPromoWithHOCs = skip(withTheme(UgcPromo));
UgcPromoWithHOCs.displayName = 'UgcPromo';

export default UgcPromoWithHOCs;

export { UgcPromo as StorybookComponent };
