import PropTypes from 'prop-types';

export default function Logo(props) {
  const {
    type,
    ...otherProps
  } = props;

  const imageAttributes = {
    src: `/icons/secretmag-logo-${type}.svg`,
    alt: 'secretmag.ru',
    ...otherProps,
  };

  return <img {...imageAttributes} />;
}

Logo.propTypes = {
  type: PropTypes.oneOf([
    'light',
    'dark',
  ]).isRequired,
};

Logo.defaultProps = {
  type: 'light',
};
