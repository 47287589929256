import PropTypes from 'prop-types';

import color from 'core/libs/color';
import resolveRelationships from 'core/utils/relationships';

import withTheme from 'core/components/theme';
import themeSwitcher from 'core/components/themeSwitcher';

import Feed from 'core/components/Feed';
import Block from 'core/components/GridBlock';
import SmartImage from 'core/components/SmartImage';

import {
  Indent,
} from 'site/components/Wrappers';

import modelPropTypes, {
  topicAttributes,
} from 'core/utils/prop-types/model';

import { CardMainFadedHeroL } from 'site/cards/CardMainFadedHero';

import CardUnderlined from './CardUnderlined';
import styles from './desktop.styl';

const relationships = resolveRelationships(['image'], { versions: {} });


function FadedDesktop(props) {
  const {
    content,
    theme,
  } = props;

  const contentColor = theme.colors.content;
  const contentColorFaded = color(contentColor).alpha(0.7).string();
  const { original: cover } = relationships(content[0]).image.versions;
  const [heroTopic, ...otherTopics] = content;
  const feedColumns = otherTopics.length >= 3 ? 3 : otherTopics.length;

  return (
    <div className={styles.container}>
      <style jsx>{`
        .${styles.container}
          background ${contentColor}
        .${styles.img}:after
          background linear-gradient(to right, ${contentColor}, ${contentColorFaded} 48%)
      `}</style>
      {!!cover &&
        <div className={styles.img}>
          <SmartImage
            src={cover}
            maxWidth={502}
            aspectRatio={1}
          />
        </div>
      }
      <div className={styles.content}>
        <Block width={6}>
          <CardMainFadedHeroL content={heroTopic} />
        </Block>
        <Indent bottom={30} />
        <Feed
          card={CardUnderlined}
          content={otherTopics}
          interitemSpacing={20}
          columns={feedColumns}
          grid
        />
      </div>
    </div>
  );
}

FadedDesktop.propTypes = {
  content: PropTypes.arrayOf(modelPropTypes(topicAttributes)),
  theme: PropTypes.object,
};

export default themeSwitcher('dark')(withTheme(FadedDesktop));
