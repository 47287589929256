import { Fragment } from 'react';
import PropTypes from 'prop-types';

import coreMarkdown, { SITE_INJECTION } from 'core/components/TopicContent/blocks/markdown';

import UgcPromo from 'site/components/UgcPromo';
import { Indent } from 'site/components/Wrappers';
import CardTopicBg from 'site/components/CardTopicBg';


export default function Markdown(props) {
  const {
    block,
    parentProps,
    ctx,
    parentProps: {
      isMobile,
      topic,
    },
  } = props;

  const {
    attributes: {
      topic_type: topicType,
    },
  } = topic.content;

  const {
    lastMarkdownId,
  } = ctx;

  const isUgc = topicType === 'ugc';
  const isCard = topicType === 'card';
  const CoreWidgetWrapper = isCard ? CardTopicBg : Fragment;
  const isLastMarkdown = block.id === lastMarkdownId;
  const ugcPromoMarkup = isUgc && isLastMarkdown
    ? (<Indent bottom={20}>
      <UgcPromo
        size={isMobile ? 's' : 'm'}
        button
      />
    </Indent>)
    : null;

  const treeItemCallback = isUgc && isLastMarkdown
    ? (children, tree, node, index) => {
      const { length: treeLength } = tree.children;
      const insertHere =
        (treeLength > 2 && index === treeLength - 3)
        || (treeLength <= 2 && index === 0);

      if (insertHere) {
        children.push({
          type: 'element',
          data: {
            hName: SITE_INJECTION,
          },
          position: node.position,
        });
      }
    }
    : null;

  return (
    <CoreWidgetWrapper>
      {coreMarkdown(block, parentProps, ctx, treeItemCallback, ugcPromoMarkup)}
    </CoreWidgetWrapper>
  );
}

Markdown.propTypes = {
  block: PropTypes.object,
  parentProps: PropTypes.object,
  ctx: PropTypes.object,
};
