import { denormalizeData } from 'core/utils/api';


export default (customParams) => (componentProps) => {
  const props = { ...componentProps, ...customParams };

  const {
    bebopApi,
    consoleError,
  } = props;

  return bebopApi
    .getTopics({
      limit: 7,
      include: 'image',
      list: 'video',
      with_filtered_count: 1,
      fields: ['headline', 'link', 'topic_type'],
    })
    .then(denormalizeData)
    .catch(consoleError('footer', []));
};
