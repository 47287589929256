import PropTypes from 'prop-types';

import withTheme from 'core/components/theme';
import { withBreakpoint } from 'core/components/breakpoint';

import Drum from  'core/components/Drum';
import { Block, Section } from 'core/components/Grid';
import Feed from 'core/components/Feed';

import { Indent, NegativeMobile } from 'site/components/Wrappers';
import WrappedCard from 'site/components/Wrappers/WrappedCard';

import styles from './index.styl';


function LetteredList({ content, theme, isMobile }) {
  const {
    topics,
    injections = {},
    itemCard: ItemCard,
    letter,
  } = content;

  const spacing = isMobile ? 30 : 40;

  return (
    <div className={styles.container}>
      <style jsx>{`
        .${styles.letter}
          font bold 24px/26px ${theme.fonts.display}
          color ${theme.colors.hintLight}
          :global(.mobile) &
            font bold 18px/22px ${theme.fonts.display}
            color ${theme.colors.content}
            background-color ${theme.colors.primary}
            &:after
              background-image linear-gradient(to right, ${theme.colors.hintLight}, transparent 1px)
        `}
      </style>
      {topics.map((topic, i) => {
        const isArrayInjected = Array.isArray(injections[i]);
        const isCarousel = isArrayInjected && injections[i].length > 2;
        const Wrapper = isCarousel ? Drum : Feed;

        const carouselProps = {
          fadeNext: 'linear-gradient(to left, transparent 50%, rgba(22, 23, 29, 0.9) 100%)',
          fadePrev: 'linear-gradient(to right, transparent 50%, rgba(22, 23, 29, 0.9) 100%)',
          arrowsShift: -10,
        };

        return (
          <Section key={i}>
            <Block desktop={2} mobile={12}>
              {i === 0 && <div className={styles.letter}>{letter}</div>}
            </Block>
            <Block
              desktop={9}
              mobile={12}
            >
              <ItemCard content={topic} />
              <Indent top={spacing} bottom={spacing} />
              {isArrayInjected &&
                <Indent
                  left={isMobile ? 20 : 0}
                  bottom={40}
                >
                  <NegativeMobile>
                    <Wrapper
                      card={WrappedCard}
                      content={injections[i]}
                      interitemSpacing={10}
                      itemWidth={240}
                      columns={injections[i].length}
                      showArrowsOnHover
                      grid
                      {...isCarousel && carouselProps}
                    />
                  </NegativeMobile>
                </Indent>
              }
            </Block>
          </Section>
        );
      })}
      <Block />
    </div>
  );
}

LetteredList.propTypes = {
  content: PropTypes.object,
  theme: PropTypes.object,
  isMobile: PropTypes.bool,
};

export default withBreakpoint(withTheme(LetteredList));
