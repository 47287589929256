import PropTypes from 'prop-types';
import withBreakpoint from 'core/components/breakpoint/withBreakpoint';
import Indent from './Indent';

import { SIDE_INDENT, SIDE_INDENT_MOBILE } from 'site/constants';

function SideIndent(props) {
  const {
    children,
    isMobile,
  } = props;

  const horizontal = isMobile ? SIDE_INDENT_MOBILE : SIDE_INDENT;

  return (
    <Indent
      left={horizontal}
      right={horizontal}
    >
      {children}
    </Indent>
  );
}

SideIndent.propTypes = {
  isMobile: PropTypes.bool,
};

export default withBreakpoint(SideIndent);
