
import PropTypes from 'prop-types';

import {
  rawRubricFetcher,
  rawTopicsFetcher,
} from 'core/fetchers/rubric';

import resolve from 'core/resolver/resolve';

import { filterRequiredParams } from 'core/utils/api';

import Rubric from 'core/components/Rubric';
import ColumnLayout from 'core/components/ColumnLayout';

import { PageIndent } from 'core/components/Wrappers';
import withPageHocs from 'core/components/withPageHocs';

import InnerSideColumn from 'site/components/InnerSideColumn';
import RubricTopics from 'site/components/RubricTopics';
import Boroda from 'site/components/Boroda';
import ListHeader from 'site/components/ListHeader';
import { PageBoroda } from 'site/components/Wrappers';

import { RUBRICS_PAGE_LIMIT } from 'site/constants';

import footerFetcher from 'site/fetchers/footer';

import { CardVertical1 } from 'site/cards/CardVertical';
import CardHorizontal from 'site/cards/CardHorizontal';
import CompareCard from 'site/cards/CompareCard';


function RubricPage({ rawRubric, rawTopics, footerContent }) {
  return (
    <PageBoroda content={footerContent}>
      <PageIndent>
        <ColumnLayout
          rightColumn={(
            <InnerSideColumn />
          )}
        >
          <Rubric
            rawRubric={rawRubric}
            rawTopics={rawTopics}
            limit={RUBRICS_PAGE_LIMIT}
            titleComponent={ListHeader}
          >
            {({ topics }) => (
              <RubricTopics topics={topics} />
            )}
          </Rubric>
          <Boroda recommenderColumns={3} />
        </ColumnLayout>
      </PageIndent>
    </PageBoroda>
  );
}

RubricPage.propTypes = {
  rawRubric: PropTypes.object,
  rawTopics: PropTypes.object,
  footerContent: PropTypes.array,
};

const dataProvider = resolve({
  rawRubric: rawRubricFetcher(),
  rawTopics: rawTopicsFetcher({
    limit: RUBRICS_PAGE_LIMIT,
    fields: filterRequiredParams([CardVertical1, CardHorizontal, CompareCard], 'fields'),
    include: filterRequiredParams([CardVertical1, CardHorizontal, CompareCard], 'include'),
  }),
  footerContent: footerFetcher(),
});

export default withPageHocs(dataProvider)(RubricPage);
