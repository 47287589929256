export default {
  0: {},
  1: {
    rubricAsLabel: true,
  },
  2: {
    rubricAsLabel: true,
    rubricLabelAtRight: true,
  },
  3: {
    rubricLabelAtTop: true,
  },
};
