import PropTypes from 'prop-types';

import resolve from 'core/resolver/resolve';

import CoreLegal from 'core/components/LegalPage';
import withPageHocs from 'core/components/withPageHocs';

import footerFetcher from 'site/fetchers/footer';

import { PageBoroda } from 'site/components/Wrappers';


function LegalPage({ footerContent }) {
  return (
    <PageBoroda content={footerContent}>
      <CoreLegal />
    </PageBoroda>
  );
}

LegalPage.propTypes = {
  footerContent: PropTypes.array,
};

const dataProvider = resolve({
  footerContent: footerFetcher(),
});

export default withPageHocs(dataProvider)(LegalPage);
