import PropTypes from 'prop-types';

import { rawAuthorsFetcher } from 'core/fetchers/authorsPage';

import resolve from 'core/resolver/resolve';

import withPageHocs from 'core/components/withPageHocs';
import withBreakpoint from 'core/components/breakpoint/withBreakpoint';
import AuthorsPage from 'core/components/AuthorsPage';
import ColumnLayout from 'core/components/ColumnLayout';
import { Indent, PageIndent } from 'core/components/Wrappers';

import InnerSideColumn from 'site/components/InnerSideColumn';
import Boroda from 'site/components/Boroda';
import UgcPromo from 'site/components/UgcPromo';
import CardAuthor, { CardAuthorS } from 'site/cards/CardAuthor';
import { PageBoroda } from 'site/components/Wrappers';

import footerFetcher from 'site/fetchers/footer';


function Authors({ rawAuthors, footerContent, isMobile }) {
  const feedProps = {
    card: isMobile ? CardAuthorS : CardAuthor,
    columns: 1,
    grid: false,
  };

  return (
    <PageBoroda content={footerContent}>
      <PageIndent>
        <ColumnLayout rightColumn={<InnerSideColumn />}>
          <Indent top={40} />
          <UgcPromo
            button
            size={isMobile ? 's' : 'm'}
          />
          <Indent top={60} />
          <h1>Авторы СФ</h1>
          <Indent top={40} />
          <AuthorsPage
            rawAuthors={rawAuthors}
            {...feedProps}
          />
          <Boroda recommenderColumns={3} />
        </ColumnLayout>
      </PageIndent>
    </PageBoroda>
  );
}


Authors.propTypes = {
  rawAuthors: PropTypes.object,
  footerContent: PropTypes.array,
  isMobile: PropTypes.bool,
};

const dataProvider = resolve({
  rawAuthors: rawAuthorsFetcher(),
  footerContent: footerFetcher(),
});

export default withPageHocs(dataProvider)(withBreakpoint(Authors));
