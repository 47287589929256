import { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'core/libs/router';
import { compose } from 'core/libs/recompose';

import { initialAppStatusProvider } from 'core/components/BaseRoutes';

import EmptyWrapper from 'core/components/EmptyWrapper';
import StoryTopics from 'core/components/stories/StoryTopics';
import { StickyContainer } from 'core/components/Shapka';
import Layout, { Header, Content, Footer } from 'core/components/Layout';
import { Mobile, Desktop, withBreakpoint } from 'core/components/breakpoint';
import themeSwitcher from 'core/components/themeSwitcher';
import { Indent } from 'core/components/Wrappers';
import AdWrapper from 'core/components/Ad/AdWrapper';
import withTheme from 'core/components/theme';
import withGlobalTopicContext from 'core/components/GlobalTopicProvider/withGlobalTopicContext';

import isError4xx from 'core/utils/statuscode-helper';
import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';

import Themer from '/site/components/Themer';
import Scooter from '/site/components/Scooter';
import DarkWrapper from 'site/components/DarkWrapper';
import Shapka from 'site/components/Shapka';

import {
  Billboard,
  Background,
  Fullscreen as DesktopFullscreen,
} from 'site/components/Ads/desktop';
import {
  TopBanner,
  Rich,
  Fullscreen as MobileFullscreen,
} from 'site/components/Ads/mobile';

import { isEnciklopediya } from 'site/utils';

import {
  SIDE_INDENT,
  LIFT_PATHNAME,
  ENCIKLOPEDIYA_PATHNAME,
} from 'site/constants';

import styles from './index.styl';


const DarkWrapperComponent = themeSwitcher('enciklopediya')(DarkWrapper);

function isError(props) {
  const {
    location,
    initialAppStatus,
  } = props;

  return isError4xx(initialAppStatus?.appStatus || location?.state?.appStatus);
}

function getThemeName(props) {
  const {
    location: {
      pathname,
    },
  } = props;

  const isEnciklopediyaPath = isEnciklopediya(pathname);
  const isErrorPage = isError(props);

  return isErrorPage || isEnciklopediyaPath
    ? 'enciklopediya'
    : 'light';
}

class SiteLayout extends Component {
  state = {
    themeName: getThemeName(this.props),
  };

  static getDerivedStateFromProps(props, state) {
    const {
      location,
    } = props;

    const {
      themeName: prevThemeName,
    } = state;

    const themeName = getThemeName(props);

    if (themeName === prevThemeName || location?.state?.infinity) {
      return null;
    }

    return  {
      themeName,
    };
  }

  render() {
    const {
      children,
      isMobile,
      theme,
      location: {
        pathname,
      },
      content,
    } = this.props;

    const {
      themeName,
    } = this.state;

    const {
      is_premium: isPremiumTopic,
    } = content?.attributes || {};

    const { indents } = theme.layout;

    const mainPageEnciklopediya = pathname === ENCIKLOPEDIYA_PATHNAME;

    const isErrorPage = isError(this.props);

    const disableAds = pathname === LIFT_PATHNAME || isErrorPage || mainPageEnciklopediya;

    const Wrapper = themeName === 'enciklopediya' ? DarkWrapperComponent : EmptyWrapper;

    const ShapkaWrapper = isMobile ? EmptyWrapper : Indent;

    return (
      <StickyContainer>
        <style jsx>{`
          .${styles.stories}
            :global(.desktop) &
              margin-left ${indents.left}px

          .${styles.storiesContent}
            padding-right ${indents.left * 2}px

            :global(.mobile) &
              padding-left ${indents.left}px
              padding-right ${indents.left}px
        `}</style>
        <Wrapper>
          <Layout>
            <Themer themeName={themeName}>
              <Header>
                <ShapkaWrapper left={SIDE_INDENT} right={SIDE_INDENT}>
                  <Shapka isSecondShapkaForPremium={isPremiumTopic} fullwidth />
                </ShapkaWrapper>
              </Header>
              <Content>
                {(!disableAds && !isPremiumTopic) && (
                  <AdWrapper bottom={20}>
                    <Desktop>
                      <Indent left={SIDE_INDENT} right={SIDE_INDENT}>
                        <Billboard />
                      </Indent>
                    </Desktop>
                    <Mobile>
                      <TopBanner />
                    </Mobile>
                  </AdWrapper>
                )}
                {!isPremiumTopic && (
                  <div className={styles.stories}>
                    <div className={styles.storiesContent}>
                      <StoryTopics />
                    </div>
                  </div>
                )}
                {children}
              </Content>
              <Footer>
                <Themer themeName={themeName === 'enciklopediya' ? 'enciklopediya' : 'dark'}>
                  <Scooter />
                </Themer>
              </Footer>
            </Themer>
          </Layout>
        </Wrapper>
        {!disableAds && (
          <>
            <Desktop>
              <Background />
              <DesktopFullscreen />
            </Desktop>
            <Mobile>
              <Rich />
              <MobileFullscreen />
            </Mobile>
          </>
        )}
      </StickyContainer>
    );
  }
}

SiteLayout.propTypes = {
  theme: PropTypes.object,
  isMobile: PropTypes.bool,
  location: PropTypes.object,
  history: PropTypes.object,
  initialAppStatus: PropTypes.object,
  content: modelPropTypes(topicAttributes),
};

export default compose(
  withBreakpoint,
  withTheme,
  initialAppStatusProvider,
  withRouter,
  withGlobalTopicContext,
)(SiteLayout);
