import PropTypes from 'prop-types';

import { withBreakpoint } from 'core/components/breakpoint';

import Recommender from 'core/components/Recommender';
import Drum from 'core/components/Drum';

import ListHeader from 'site/components/ListHeader';
import { Indent } from 'core/components/Wrappers';

import { CardVertical1S } from 'site/cards/CardVertical';

import styles from './recommender.styl';


function SiteRecommender({ isMobile, columns }) {
  const desktopWidth = Number.parseFloat(100 / columns).toFixed(1) + '%';

  return (
    <Indent right={isMobile ? -20 : 0}>
      <ListHeader level={2} bottomIndent={25}><div className={styles.title}>Популярное</div></ListHeader>
      <Recommender
        blockId={isMobile ? 'f859c7afb21140b5b7b9e13422eab26c' : '635c423a3fd94f60a40d3048f6da8827'}
        renderer={Drum}
        itemWidth={isMobile ? '270px' : desktopWidth}
        spaceBetween={isMobile ? 20 : 40}
        card={CardVertical1S}
        limit={12}
        {...!isMobile && {
          showArrowsOnHover: true,
          arrowsShift: -10,
          arrowsShiftVertical: 90,
        }}
      />
    </Indent>
  );
}

SiteRecommender.propTypes = {
  isMobile: PropTypes.bool,
  columns: PropTypes.number,
};

SiteRecommender.defaultProps = {
  columns: 4,
};

export default withBreakpoint(SiteRecommender);
