import { Fragment } from 'react';
import PropTypes from 'prop-types';

import coreSnippet from 'core/components/TopicContent/blocks/snippet';

import CardTopicBg from 'site/components/CardTopicBg';

import { TOPIC_VIKONTS_INDENT } from 'site/constants';


export default function Snippet({ block, parentProps }) {
  const {
    topic,
  } = parentProps;

  const isCard = topic.content.attributes.topic_type === 'card';

  const Wrapper = isCard
    ? CardTopicBg
    : Fragment;

  return (
    <Wrapper>
      <div className='snippetWrapper'>
        <style jsx>{`
          .snippetWrapper
            padding ${isCard ? `${TOPIC_VIKONTS_INDENT}px` : 0}
        `}</style>
        {coreSnippet(block)}
      </div>
    </Wrapper>
  );
}

Snippet.propTypes = {
  block: PropTypes.shape({
    html: PropTypes.string,
    css: PropTypes.number,
    js: PropTypes.number,
  }),
  parentProps: PropTypes.object,
};
