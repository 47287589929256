export const HOST = 'secretmag.ru';

// indents
export const SIDE_INDENT = 40;
export const SIDE_INDENT_MOBILE = 20;
export const VERTICAL_INDENT = 30;
export const VERTICAL_INDENT_MOBILE = 14;
export const TOPIC_VIKONTS_INDENT = 20;
export const TOPIC_INDENT_LEFT = 130;


// indent for feed with CardMain
export const FEED_INDENT = 25;

// limits
export const RUBRICS_PAGE_LIMIT = 12;
export const ENCIKLOPEDIYA_PAGE_LIMIT = 10;

// sizes
export const LAYOUT_MAX_WIDTH = 1280;
export const LAYOUT_MAX_WIDTH_MOBILE = 600;
export const CONTENT_AREA = 890;
export const CONTENT_TEXT_AREA = 660;
export const RIGHT_COLUMN_WIDTH = 300;
export const AVATAR_SIZE = 110;
export const AVATAR_SIZE_BIG = 240;
export const CARD_IMAGE_HEIGHT_MOBILE = 240;

// archive years
export const DEFAULT_YEAR_START = 2002;
export const DEFAULT_YEAR_END = 2014;

export const AD_LABEL_IS_PARTNER = 'Партнерский материал';

export const SHARE = [
  'vkontakte',
  'telegram',
  'odnoklassniki',
  'twitter',
  'whatsapp',
  'email',
  'copy',
];

export const LIFT_PATHNAME = '/lift';
export const FEEDBACK_PATHNAME = '/stories/feedback.htm';

export const PMEF_TAG = 'pmef-2021-novosti';

export const ENCIKLOPEDIYA_SLUG = 'enciklopediya';
export const ENCIKLOPEDIYA_PATHNAME = '/enciklopediya';
