import PropTypes from 'prop-types';

import resolve from 'core/resolver/resolve';

import CoreRulesRecommenders from 'core/components/RulesRecommenders';
import withPageHocs from 'core/components/withPageHocs';

import footerFetcher from 'site/fetchers/footer';

import { PageBoroda } from 'site/components/Wrappers';


function RulesRecommenders({ footerContent, mail }) {
  return (
    <PageBoroda content={footerContent}>
      <CoreRulesRecommenders mail={mail} />
    </PageBoroda>
  );
}

RulesRecommenders.propTypes = {
  footerContent: PropTypes.array,
  mail: PropTypes.string,
};

const dataProvider = resolve({
  footerContent: footerFetcher(),
});

export default withPageHocs(dataProvider)(RulesRecommenders);
