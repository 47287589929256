import PropTypes from 'prop-types';

import { Mobile, Desktop } from 'core/components/breakpoint';

import { Context } from 'site/components/Ads/desktop';
import { Footer as FootBanner } from 'site/components/Ads/mobile';
import { SideIndent } from 'site/components/Wrappers';


export default function FooterAds({ contextCount }) {
  return (
    <SideIndent>
      <Desktop>
        <Context count={contextCount} />
      </Desktop>
      <Mobile>
        <FootBanner />
      </Mobile>
    </SideIndent>
  );
}

FooterAds.propTypes = {
  contextCount: PropTypes.number,
};

FooterAds.defaultProps = {
  contextCount: 1,
};
