import stylesAtomsFactory from 'core/themes/styles-atoms';
import stylesAtomsMobileFactory from 'core/themes/styles-atoms-mobile.js';

import baseTheme from './base';
import baseMobileTheme from './mobile';

import darkProperties from './dark';
import lightProperties from './light';
import enciklopediyaProperties from './enciklopediya';


const base = atoms => stylesAtomsFactory(baseTheme(atoms));

export const light = base(lightProperties);
export const dark = base(darkProperties);
export const enciklopediya = base(enciklopediyaProperties);

export const lightMobile = stylesAtomsMobileFactory(light, baseMobileTheme(lightProperties));
export const darkMobile = stylesAtomsMobileFactory(dark, baseMobileTheme(darkProperties));
export const enciklopediyaMobile = stylesAtomsMobileFactory(dark, baseMobileTheme(enciklopediyaProperties));
