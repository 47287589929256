import PropTypes from 'prop-types';

import CoreAvatar from 'core/components/Avatar';

import UgcLabel from 'site/components/UgcLabel';

import styles from './index.styl';


function Avatar(props) {
  const {
    isUgc,
    ...avatarProps
  } = props;

  return (
    <div className={styles.wrapper}>
      <CoreAvatar {...avatarProps} />
      {isUgc && <UgcLabel className={styles.ugcLabel} />}
    </div>
  );
}

Avatar.propTypes = {
  /** Флаг отображения метки UGC */
  isUgc: PropTypes.bool,
};

export default Avatar;
