import { Fragment } from 'react';
import PropTypes from 'prop-types';

import { withBreakpoint } from 'core/components/breakpoint';
import { withRouter } from 'core/libs/router';

import Feed from 'core/components/Feed';
import AdWrapper from 'core/components/Ad/AdWrapper';

import { isGameCompare } from 'core/components/GameCompare/utils';

import { Indent } from 'site/components/Wrappers';

import { CardVertical1 } from 'site/cards/CardVertical';
import CardHorizontal from 'site/cards/CardHorizontal';

import {
  SuperFooter,
  Context,
  Native1AsCardHorizontal as Native1,
  Parallax,
} from 'site/components/Ads/desktop';

import {
  Listing1,
  Listing2,
  ListingSpec,
} from 'site/components/Ads/mobile';

import GameCompareRubric from './GameCompareRubric';

const spacing = 20;


function RubricTopics(props) {
  const {
    topics,
    isDesktop,
    location,
  } = props;

  const isGameComparePage = isGameCompare(location.pathname);

  if (isGameComparePage) {
    return <GameCompareRubric topics={topics} isDesktop={isDesktop} />;
  }

  const feedProps = {
    interitemSpacing: spacing,
    card: isDesktop ? CardHorizontal : CardVertical1,
  };

  if (isDesktop) {
    const first = topics.slice(0, 2);
    const second = topics.slice(2, 5);
    const third = topics.slice(5, 7);
    const fourth = topics.slice(7, 9);
    const fifth = topics.slice(9, topics.length);

    return (
      <AdWrapper top={spacing}>
        {first.length > 0 && (
          <Fragment>
            <Feed
              {...feedProps}
              content={first}
            />
            <Native1 />
          </Fragment>
        )}
        {second.length > 0 && (
          <Fragment>
            <Indent top={spacing} />
            <Feed {...feedProps} content={second} />
            <SuperFooter />
          </Fragment>
        )}
        {third.length > 0 && (
          <Fragment>
            <Indent top={spacing} />
            <Feed {...feedProps} content={third} />
            <Native1 />
          </Fragment>
        )}
        {fourth.length > 0 && (
          <Fragment>
            <Indent top={spacing} />
            <Feed {...feedProps} content={fourth} />
            <Parallax />
          </Fragment>
        )}
        {fifth.length > 0 && (
          <Fragment>
            <Indent top={spacing} />
            <Feed {...feedProps} content={fifth} />
            <Context />
          </Fragment>
        )}
        <Indent bottom={spacing} />
      </AdWrapper>
    );
  }

  const first = topics.slice(0, 3);
  const second = topics.slice(3, 6);
  const third = topics.slice(6, 9);
  const fourth = topics.slice(9, topics.length);

  return (
    <AdWrapper top={spacing} bottom={spacing}>
      {first.length > 0 && (
        <Fragment>
          <Feed
            {...feedProps}
            content={first}
          />
          <Listing1 />
          <Indent top={spacing} />
        </Fragment>
      )}
      {second.length > 0 && (
        <Fragment>
          <Feed {...feedProps} content={second} />
          <ListingSpec dividerPosition='top' dividerIndent={spacing} />
          <Indent top={spacing} />
        </Fragment>
      )}
      {third.length > 0 && (
        <Fragment>
          <Feed {...feedProps} content={third} />
          <Listing2 />
          <Indent top={spacing} />
        </Fragment>
      )}
      {fourth.length > 0 && (
        <Fragment>
          <Feed {...feedProps} content={fourth} />
        </Fragment>
      )}
      <Indent bottom={spacing} />
    </AdWrapper>
  );
}

RubricTopics.propTypes = {
  topics: PropTypes.array,
  isDesktop: PropTypes.bool,
  location: PropTypes.object,
};

export default withRouter(withBreakpoint(RubricTopics));
