import PropTypes from 'prop-types';
import { compose } from 'core/libs/recompose';

import bindProps from 'core/components/bindProps';

import Ad from 'core/components/Ad';
import AdBillboard from 'core/components/Ad/Billboard';

import sponsoredStyles from 'core/components/Ad/styles/sponsoredStyles';

import horizontalStyles from './styles/horizontal';
import inPageStyles from './styles/inPage';
import vertical from './styles/vertical';

import { RIGHT_COLUMN_WIDTH } from 'site/constants';

export const Billboard = compose(
  bindProps({
    name: 'Billboard',
    blockId: '432425130',
    hideOnPreview: true,
    options: {
      p1: 'bsbzw',
      p2: 'y',
      pct: 'a',
    },
    height: 250,
  }),
)(AdBillboard);

export const Background = bindProps({
  name: 'Background',
  blockId: '435055994',
  hideOnPreview: true,
  options: {
    p1: 'bjijb',
    p2: 'enhl',
    pct: 'a',
  },
})(Ad);

export const Fullscreen = bindProps({
  name: 'Fullscreen',
  blockId: '432425230',
  hideOnPreview: true,
  options: {
    'p1': 'buoib',
    'p2': 'emiu',
    'pct': 'a',
  },
})(Ad);

export const Ad240x400 = bindProps({
  name: '240x400',
  width: RIGHT_COLUMN_WIDTH,
  height: 600,
  blockId: '432425134',
  stickyTemp: 3,
  hideOnPreview: true,
  options: {
    p1: 'bsbzz',
    p2: 'emhk',
    pct: 'a',
  },
})(Ad);

export const Ad240x200 = bindProps({
  name: '240x200',
  width: RIGHT_COLUMN_WIDTH,
  height: 200,
  lazy: true,
  blockId: '542995757',
  hideOnPreview: true,
})(Ad);

export const Ad240x400Second = bindProps({
  name: '240x400_2nd',
  width: RIGHT_COLUMN_WIDTH,
  height: 600,
  lazy: true,
  blockId: '432425140',
  hideOnPreview: true,
  options: {
    p1: 'bscac',
    p2: 'ewqs',
    pct: 'a',
  },
})(Ad);

const ContextAd = bindProps({
  name: 'Context',
  // предполагаем что чаще всего в это место прилетает yandex direct
  height: 290,
  lazy: true,
  blockId: '436825828',
  hideOnPreview: true,
  options: {
    p1: 'buohz',
    p2: 'ewzc',
    pct: 'a',
  },
})(Ad);

export function Context({ count }) {
  return <ContextAd manualPuids={{ puid44: `context_item${count}` }} />;
}

Context.defaultProps = {
  count: 1,
};

Context.propTypes = {
  count: PropTypes.number,
};

export const Sponsored = compose(
  bindProps({
    name: 'Sponsored',
    blockId: '444596018',
    hideOnPreview: true,
    options: {
      'p1': 'bwuho',
      'p2': 'fomx',
      'pct': 'c',
    },
  }),
  sponsoredStyles,
)(Ad);

export const SuperFooter = bindProps({
  name: '100%x240',
  height: 240,
  lazy: true,
  blockId: '432425200',
  hideOnPreview: true,
  options: {
    p1: 'bscaf',
    p2: 'fcuz',
    pct: 'a',
  },
})(Ad);

export const Parallax = bindProps({
  name: 'Parallax',
  height: 250,
  lazy: true,
  blockId: '444595564',
  hideOnPreview: true,
  options: {
    'p1': 'bwuhn',
    'p2': 'fhoe',
    'pct': 'a',
  },
})(Ad);

export const AdCenter = bindProps({
  name: 'Center',
  height: 240,
  lazy: true,
  blockId: '444595278',
  hideOnPreview: true,
  options: {
    'p1': 'bwuhm',
    'p2': 'fomw',
    'pct': 'a',
  },
})(Ad);

export const InRead = bindProps({
  name: 'inRead',
  height: 300,
  blockId: '432425204',
  hideOnPreview: true,
  options: {
    p1: 'bscag',
    p2: 'fcvb',
    pct: 'a',
  },
})(Ad);

export const Native1AsCardHorizontal = compose(
  bindProps({
    name: 'Native1',
    blockId: '433743490',
    hideOnPreview: true,
    options: {
      p1: 'buohx',
      p2: 'fhzr',
      pct: 'a',
    },
  }),
  horizontalStyles,
)(Ad);

export const InPage = compose(
  bindProps({
    name: 'In-page 300x300',
    lazy: true,
    blockId: '448994024',
    hideOnPreview: true,
    options: {
      'p1': 'buwdz',
      'p2': 'fjgk',
      'pct': 'a',
    },
  }),
  inPageStyles
)(Ad);

export const Native1AsCardVertical = compose(
  bindProps({
    name: 'Native1',
    blockId: '433743490',
    hideOnPreview: true,
    options: {
      p1: 'buohx',
      p2: 'fhzr',
      pct: 'a',
    },
  }),
  vertical,
)(Ad);

export const Native2AsCardVertical = compose(
  bindProps({
    name: 'Native2',
    blockId: '433743492',
    hideOnPreview: true,
    options: {
      p1: 'buohy',
      p2: 'fhzs',
      pct: 'a',
    },
  }),
  vertical,
)(Ad);

export const InGallery = bindProps({
  name: 'InGallery',
  blockId: '543043995',
  disableAutoInit: true,
  hideOnPreview: true,
})(Ad);
