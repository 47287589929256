import PropTypes from 'prop-types';
import cx from 'classnames';

import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';

import skip from 'core/resolver/skip';
import withTheme from 'core/components/theme';
import bindProps from 'core/components/bindProps';

import Link from 'core/components/Link';

import CardInfoLine from 'site/components/CardInfoLine';

import Lightning from 'site/icons/Lightning';

import UgcLabel from 'site/components/UgcLabel';

import {
  isTopicImportant,
} from 'site/utils';

import styles from './index.styl';

const requiredPayloadFields = [
  'link',
  'headline',
  'list_headline',
  'alternative_headline',
  'topic_type',
  'published_at',
];


function CardMainHero(props) {
  const {
    content,
    size,
    theme,
  } = props;

  if (!content) return null;

  const {
    link,
    headline,
    list_headline: listHeadline,
    alternative_headline: altHeadline,
    topic_type: topicType,
    published_at: publishedAt,
  } = content.attributes;

  const isUgc = topicType === 'ugc';
  const isImportant = isTopicImportant(content);

  return (
    <div
      className={cx(
        styles.card,
        isImportant && '_important',
        styles[`_size_${size}`],
      )}
    >
      <style jsx>{`
        .${styles.lightningWrapper}
          background ${theme.colors.yellow}
          color ${theme.colors.content}
        .${styles.headline}
          font-family ${theme.fonts.display}
          color ${theme.colors.primary}
        .${styles.altHeadline}
          font-family ${theme.fonts.text}
          color ${theme.colors.primary}
      `}</style>
      <Link to={link} type='secondary'>
        <div>
          <div className={styles.headline}>
            {isImportant && (
              <div className={styles.lightningWrapper}>
                <Lightning className={styles.lightning} />
              </div>
            )}
            {listHeadline || headline}
          </div>
          {altHeadline &&
            <div className={cx(styles.altHeadline, 'altHeadline')}>
              {altHeadline}
            </div>
          }
          <div className={styles.info}>
            <CardInfoLine
              publishedAt={publishedAt}
            />
            {isUgc && <UgcLabel className={styles.ugcLabel} circle />}
          </div>
        </div>
      </Link>
    </div>
  );
}

CardMainHero.propTypes = {
  /** Контент топика */
  content: modelPropTypes(topicAttributes),
  /** @ignore */
  theme: PropTypes.object.isRequired,
  /** Размер карточки */
  size: PropTypes.oneOf(['s', 'm']),
};

CardMainHero.defaultProps = {
  size: 'm',
};

const Card = skip(withTheme(CardMainHero));

Card.displayName = 'CardMainHero';
Card.requiredPayloadFields = requiredPayloadFields;

export const CardMainHeroS = bindProps({ size: 's' })(Card);

export default Card;

export { CardMainHero as StorybookComponent };
