import PropTypes from 'prop-types';
import { incut as coreIncut } from 'core/components/TopicContent/blocks';

import styles from './index.styl';


export default function Incut(props) {
  const {
    block,
    parentProps,
    ctx,
  } = props;

  const {
    theme,
  } = parentProps;

  return (
    <div className={styles.container}>
      <style jsx>{`
        .${styles.quotes}
          &:before
          &:after
            background-color ${theme.controls.vikontIncut.borderColor}
      `}</style>
      <div className={styles.quotes}>“</div>
      {coreIncut(block, parentProps, ctx)}
    </div>
  );
}

Incut.propTypes = {
  block: PropTypes.object,
  parentProps: PropTypes.object,
  ctx: PropTypes.object,
};
