import oneLine from 'common-tags/lib/oneLine';

import Montserrat800 from './Montserrat/Montserrat-ExtraBold.woff';
import Montserrat800woff2 from './Montserrat/Montserrat-ExtraBold.woff2';
import Montserrat700 from './Montserrat/Montserrat-Bold.woff';
import Montserrat700woff2 from './Montserrat/Montserrat-Bold.woff2';
import Montserrat700i from './Montserrat/Montserrat-BoldIt.woff';
import Montserrat700iwoff2 from './Montserrat/Montserrat-BoldIt.woff2';
import Montserrat500 from './Montserrat/Montserrat-Medium.woff';
import Montserrat500woff2 from './Montserrat/Montserrat-Medium.woff2';
import Montserrat400 from './Montserrat/Montserrat-Regular.woff';
import Montserrat400woff2 from './Montserrat/Montserrat-Regular.woff2';
import Montserrat400i from './Montserrat/Montserrat-RegularIt.woff';
import Montserrat400iwoff2 from './Montserrat/Montserrat-RegularIt.woff2';

import MajorMonoDisplay400 from './MajorMonoDisplay/MajorMonoDisplay-Regular.woff';
import MajorMonoDisplay400woff2 from './MajorMonoDisplay/MajorMonoDisplay-Regular.woff2';

const fonts = {
  display: 'Montserrat, sans-serif',
  number: 'Major Mono Display, sans-serif',

  get nuance() {
    return this.display;
  },
  get text() {
    return this.display;
  },
  faces: [
    {
      fontFamily: 'Major Mono Display',
      fontWeight: 400,
      fontStyle: 'normal',
      src: oneLine`
        local('MajorMonoDisplay-Regular'),
        local('Major Mono Display Regular'),
        url(${MajorMonoDisplay400}) format('woff'),
        url(${MajorMonoDisplay400woff2}) format('woff2')
      `,
    },
    {
      fontFamily: 'Montserrat',
      fontWeight: 800,
      fontStyle: 'normal',
      src: oneLine`
        local('Montserrat-Extrabold'),
        local('Montserrat Extrabold'),
        url(${Montserrat800}) format('woff'),
        url(${Montserrat800woff2}) format('woff2')
      `,
    },
    {
      fontFamily: 'Montserrat',
      fontWeight: 700,
      fontStyle: 'normal',
      src: oneLine`
        local('Montserrat-Bold'),
        local('Montserrat Bold'),
        url(${Montserrat700}) format('woff'),
        url(${Montserrat700woff2}) format('woff2')
      `,
    },
    {
      fontFamily: 'Montserrat',
      fontWeight: 700,
      fontStyle: 'italic',
      src: oneLine`
        local('Montserrat-Bold-Italic'),
        local('Montserrat Bold Italic'),
        url(${Montserrat700i}) format('woff'),
        url(${Montserrat700iwoff2}) format('woff2')
      `,
    },
    {
      fontFamily: 'Montserrat',
      fontWeight: 500,
      fontStyle: 'normal',
      src: oneLine`
        local('Montserrat-Medium'),
        local('Montserrat Medium'),
        url(${Montserrat500}) format('woff'),
        url(${Montserrat500woff2}) format('woff2')
      `,
    },
    {
      fontFamily: 'Montserrat',
      fontWeight: 400,
      fontStyle: 'normal',
      src: oneLine`
        local('Montserrat-Regular'),
        local('Montserrat Regular'),
        url(${Montserrat400}) format('woff'),
        url(${Montserrat400woff2}) format('woff2')
      `,
    },
    {
      fontFamily: 'Montserrat',
      fontWeight: 400,
      fontStyle: 'italic',
      src: oneLine`
        local('Montserrat-Regular-Italic'),
        local('Montserrat Regular Italic'),
        url(${Montserrat400i}) format('woff'),
        url(${Montserrat400iwoff2}) format('woff2')
      `,
    },
  ],
};

export default fonts;
