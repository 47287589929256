import PropTypes from 'prop-types';

import Divider from 'core/components/Divider';
import MarkdownWrapper from 'core/components/MarkdownWrapper';

import modelPropTypes, { opinionAttributes } from 'core/utils/prop-types/model';

import AuthorOpinionHeader from 'site/components/AuthorOpinionHeader';

import styles from './index.styl';


export default function Opinion(props) {
  const {
    block,
    parentProps: {
      theme,
      isMobile,
    },
  } = props;

  if (!block) return null;

  const {
    attributes: {
      body,
      author,
    },
  } = block;

  const disableLine = !body;

  return (
    <div>
      <style jsx>{`
        .${styles.body}
          font 700 24px/30px ${theme.fonts.display}
          :global(.mobile) &
            font 700 16px/22px ${theme.fonts.display}
      `}</style>
      <AuthorOpinionHeader
        author={author}
        vertical={isMobile}
        disableLine={disableLine}
      />
      {body && (
        <div className={styles.body}>
          <MarkdownWrapper interitemSpacing={20}>
            {body}
          </MarkdownWrapper>
        </div>
      )}
      <Divider />
    </div>
  );
}

Opinion.propTypes = {
  block: modelPropTypes(opinionAttributes).isRequired,
  parentProps: PropTypes.object,
};
