import PropTypes from 'prop-types';
import { get } from 'core/libs/lodash';

import modelPropTypes from 'core/utils/prop-types/model';

import styles from './index.styl';
import CardTopicBg from 'site/components/CardTopicBg';


/**
 * Компонент стилизован для топиков типа "Карточка".
 * В таком топике визуальный разрыв между блоками виджетов происходит благодаря этому компоненту.
 */
export default function PageBreak(props) {
  const {
    block,
    parentProps: {
      theme,
      topic,
    },
  } = props;

  const isCard = topic.content.attributes.topic_type === 'card';

  if (!isCard) return null;

  const title = get(block, 'attributes.title');

  return (
    <div className={styles.pageBreak}>
      <style jsx>{`
        .${styles.pageBreak}
          font-family ${theme.fonts.display}

        .${styles.counter}
          font-family ${theme.fonts.display}
          :global(.desktop) &
            color ${theme.colors.hintLight}
          :global(.mobile) &:before
            background ${theme.colors.content}

        .title
          font 800 24px/26px ${theme.fonts.display}
          :global(.mobile) &
            font 800 20px/25px ${theme.fonts.display}
      `}</style>
      <CardTopicBg>
        <div className={styles.counter} />
        <div className='title' id={title}>{title}</div>
      </CardTopicBg>
    </div>
  );
}

PageBreak.propTypes = {
  block: modelPropTypes(PropTypes.shape({
    title: PropTypes.string.isRequired,
  })).isRequired,
  parentProps: PropTypes.object,
};
