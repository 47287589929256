import PropTypes from 'prop-types';

import { withBreakpoint } from 'core/components/breakpoint';

import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';

import Feed from 'core/components/Feed';
import AdWrapper from 'core/components/Ad/AdWrapper';

import CardEnciklopedy from 'site/cards/CardEnciklopedy';

import groupTopicsByFirstLetter from '../utils';
import LetteredList from '../LetteredList';


function Topics(props) {
  const {
    topics,
    isMobile,
    news,
  } = props;

  const groupedTopics = groupTopicsByFirstLetter(
    topics,
    isMobile
      ? { 0: news }
      : {
        0: news.slice(0, 5),
        6: news.slice(5),
      });

  const feedContent = [];
  groupedTopics.forEach((letterData, letter) => {
    feedContent.push({
      id: letter,
      letter,
      itemCard: CardEnciklopedy,
      ...letterData,
    });
  });

  const feedSpacing = isMobile ? 15 : 40;

  return (
    <AdWrapper top={feedSpacing}>
      <Feed
        content={feedContent}
        card={LetteredList}
        grid
      />
    </AdWrapper>
  );
}

Topics.propTypes = {
  topics: PropTypes.arrayOf(modelPropTypes(topicAttributes).isRequired),
  news: PropTypes.arrayOf(modelPropTypes(topicAttributes)),
  isMobile: PropTypes.bool,
};

export default withBreakpoint(Topics);
