import PropTypes from 'prop-types';
import cx from 'classnames';

import Flag from 'site/icons/Flag';
import Human from 'site/icons/Human.svg';

import styles from './index.styl';


function UgcLabel(props) {
  const {
    className,
    circle,
  } = props;

  return (
    <div className={cx(
      className,
      styles.wrapper,
      circle && styles._circle,
    )}
    >
      {!circle && <Flag className={styles.flag} />}
      <Human className={styles.human} />
    </div>
  );
}

UgcLabel.propTypes = {
  /** @ignore */
  className: PropTypes.string,
  /** Признак прямоугольной или круглой метки */
  circle: PropTypes.bool,
};

export default UgcLabel;
