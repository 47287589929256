import { Fragment } from 'react';
import PropTypes from 'prop-types';
import withTheme from 'core/components/theme';

import styles from './index.styl';


const NativeStyles = ({ theme }) => {
  return (
    <Fragment>
      <style jsx global>{`
        .${styles.nativeHorizontal}
          .ad_native_img
            background-color ${theme.colors.divider}
            &:after
              background ${theme.colors.yellow}
          .ad_native_content-wrapper
            border-color ${theme.colors.divider}
            &:hover
              border-color ${theme.colors.primary}
              &:before
                border-color ${theme.colors.primary}
            &:after
              background-color ${theme.colors.primary}

        .ad_label__text
          font-family ${theme.fonts.text}
          color ${theme.colors.hintLight}

        .ad_native_title_wrapper
          &:after
            border-color ${theme.colors.content}

        .ad_native_title
        .ad_native_desc
          color ${theme.colors.primary}
          font-family ${theme.fonts.display}
      `}</style>
    </Fragment>
  );
};

NativeStyles.propTypes = {
  theme: PropTypes.object,
};

export default function cardHorizontalStyle(Component) {
  return withTheme(props => (
    <div className={styles.nativeHorizontal}>
      <NativeStyles theme={props.theme} />
      <Component {...props} />
    </div>
  ));
}
