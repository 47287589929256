import { get } from 'core/libs/lodash';

import {
  ru,
  format,
  isSameYear,
  isSameDay,
  isSameMinute,
  differenceInMinutes,
} from 'core/libs/date-fns';

import resolveRelationships from 'core/utils/relationships';

import { ENCIKLOPEDIYA_PATHNAME } from 'site/constants';


/**
 * Возвращает src указанной версии image.
 * @param {Object} [image={}] - объект, соответствующий модели image
 * @param {string} [version=original] - тип аватара, который нужно получить
 * @returns {(Object|undefined)} Объект src указанной версии или undefined.
 */

export function getImageSrc(image = {}, version = 'original') {
  const path = [
    'attributes',
    'versions',
    version,
  ];

  return get(image, path);
}

/**
 * Формат даты для карточек
 * @param {string|number} publishedAt - дата в формате, поддерживаемом конструктором new Date().
 * @param {string} givenPattern - pattern для форматирования даты.
 * @returns {string}
 */

export function dateFormat(publishedAt, givenPattern) {
  const date = new Date(publishedAt);

  if (givenPattern) return format(date, givenPattern, { locale: ru });

  const now = new Date();

  let pattern = 'dd.MM.yy';

  if (isSameMinute(date, now)) {
    pattern = 'Только что';
  } else if (isSameDay(date, now)) {
    pattern = 'HH:mm';
  } else if (isSameYear(date, now)) {
    pattern = 'dd MMMM';
  }

  return format(date, pattern, { locale: ru });
}


/**
 * Преобразует количество байт в наиболее подходящие единицы.
 * Расчёт делается исходя из того, что
 * `bytes = result * (1024 ** y)`.
 * Таким образом получаем `result = bytes / (2 ** (10 * log2Of1024))`,
 * где log2Of1024 - степень, в которую надо возвести 2, чтобы получить 1024.
 *
 * @param {number|string} bytes - число байт
 * @returns {string}
 */
export function bytesToSize(bytes = 0) {
  const radix = 10;
  const log2Of1024 = 10;
  const bytesNum = +bytes;
  const units = ['байт', 'кБ', 'мБ', 'гБ', 'тБ'];

  const unitIndex = parseInt(Math.log2(bytesNum) / log2Of1024, radix);
  const fractionalDigits = unitIndex === 0 ? 0 : 1;

  const result = (bytesNum / (2 ** (unitIndex * log2Of1024))).toFixed(fractionalDigits);
  const resultString = result.toString().replace('.', ',');

  return `${resultString} ${units[unitIndex]}`;
}


/**
 * Определяет, является ли топик важным.
 * Считаем топик важным, если у него есть тег `important` и
 * он опубликован в течение последних полутора часов.
 *
 * @param {Object} topic - денормализованный топик
 * @returns {Boolean}
 */
export function isTopicImportant(topic) {
  if (!topic) return false;

  const { published_at: publishedAt } = topic.attributes;

  if (!publishedAt) return false;

  const relationships = resolveRelationships(['tags'], []);
  const { tags } = relationships(topic);

  if (!tags.some(tag => tag.attributes?.slug === 'important')) return false;

  const minutesOld = differenceInMinutes(new Date(), new Date(publishedAt));

  return minutesOld <= 90;
}

export function isEnciklopediya(pathname) {
  return pathname.indexOf(ENCIKLOPEDIYA_PATHNAME) === 0 || pathname.indexOf('/preview' + ENCIKLOPEDIYA_PATHNAME) === 0;
}

export function getLastMergedMarkdownId(widgets = []) {
  let lastMarkdownId = null;
  for (let index = widgets.length - 1; index >= 0; index--) {
    const widget = widgets[index];
    const isMarkdown = widget.type === 'markdown';
    if (isMarkdown) {
      lastMarkdownId = widget.id;
    }
    if (!isMarkdown && lastMarkdownId) {
      break;
    }
  }
  return lastMarkdownId;
}
