import modelPropTypes, { readMoreAttributes } from 'core/utils/prop-types/model';

import readMore from 'core/components/TopicContent/blocks/readMore';

import { CardVerticalS } from 'site/cards/CardVertical';
import WrappedCard from 'site/components/Wrappers/WrappedCard';

export default function ReadMore({ block }) {
  return (
    <div className='readMore' data-qa='read-more'>
      {readMore(
        block,
        {
          title: 'Читайте на тему:',
          include: 'image',
          singleCard: WrappedCard,
          feedCard: WrappedCard,
          drumCard: CardVerticalS,
        }
      )}
    </div>
  );
}

ReadMore.propTypes = {
  block: modelPropTypes(readMoreAttributes),
};
