import PropTypes from 'prop-types';

import modelPropTypes, { photoGalleryAttributes } from 'core/utils/prop-types/model';

import GalleryWithAds from 'site/components/GalleryWithAds';

import CardTopicBg from 'site/components/CardTopicBg';
import { Indent  } from 'site/components/Wrappers';


export default function PhotoGallery(props) {
  const {
    block,
    parentProps: {
      topic,
      isMobile,
    },
  } = props;

  const isCard = topic.content.attributes.topic_type === 'card';

  const marginGallery = isMobile ? 0 : 30;

  if (isCard) {
    return (
      <CardTopicBg>
        <Indent
          top={marginGallery}
          bottom={isMobile ? 5 : 25}
          left={marginGallery}
          right={marginGallery}
        >
          <GalleryWithAds photoGallery={block} />
        </Indent>
      </CardTopicBg>
    );
  }

  return (
    <GalleryWithAds photoGallery={block} />
  );
}

PhotoGallery.propTypes = {
  block: modelPropTypes(photoGalleryAttributes).isRequired,
  parentProps: PropTypes.object,
};
