import PropTypes from 'prop-types';

import resolve from 'core/resolver/resolve';

import CoreAbout from 'core/components/About';
import withPageHocs from 'core/components/withPageHocs';

import { aboutFetcher } from 'core/fetchers/about';

import footerFetcher from 'site/fetchers/footer';

import { PageBoroda } from 'site/components/Wrappers';


function AboutPage({ content, footerContent }) {
  return (
    <PageBoroda content={footerContent}>
      <CoreAbout content={content} />
    </PageBoroda>
  );
}

AboutPage.propTypes = {
  footerContent: PropTypes.array,
  content: PropTypes.object,
};

const dataProvider = resolve({
  footerContent: footerFetcher(),
  content: aboutFetcher(),
});

export default withPageHocs(dataProvider)(AboutPage);
