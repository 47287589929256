import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'core/libs/axios';
import oneLine from 'common-tags/lib/oneLine';

import resolve from 'core/resolver/resolve';

import ColumnLayout from 'core/components/ColumnLayout';
import Page from 'core/components/Page';
import Feed from 'core/components/Feed';
import Loader from 'core/components/Loader';
import withPageHocs from 'core/components/withPageHocs';
import ErrorPage from 'core/components/ErrorPage';
import { PageIndent, NegativeMobile } from 'core/components/Wrappers';

import { ViewportHeightWrapper, PageBoroda } from 'site/components/Wrappers';
import InnerSideColumn from 'site/components/InnerSideColumn';
import ListHeader from 'site/components/ListHeader';
import FooterAds from 'site/components/Ads/FooterAds';

import footerFetcher from 'site/fetchers/footer';

import CardArchive from 'site/cards/CardArchive';

import { DEFAULT_YEAR_START, DEFAULT_YEAR_END, FEED_INDENT } from 'site/constants';

import Years from './Years';


const ArchivePageBody = props => {
  const {
    match: {
      params: {
        year = String(DEFAULT_YEAR_END),
      },
    },
  } = props;

  const [archiveData, setArchiveData] = useState({});
  const [notFound, setNotFound] = useState(false);
  const currentYearData = archiveData[year];

  useEffect(() => {
    if (currentYearData) return;

    axios
      .get(`/archives/year${year}.json`, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(({ data }) => {
        if (!Array.isArray(data)) throw new Error('Archives data is not an array');
        setArchiveData(prevState => ({
          [year]: data.sort((a, b) => {
            if (a.title > b.title) return 1;
            if (a.title < b.title) return -1;
            return 0;
          }),
          ...prevState,
        }));
      })
      .catch(() => setNotFound(true));
  }, [currentYearData, year]);

  if (notFound) {
    return <ErrorPage />;
  }

  const pageContent = currentYearData
    ? (
      <Feed
        grid
        columns={3}
        card={CardArchive}
        content={currentYearData}
        interitemSpacing={FEED_INDENT}
      />
    ) : <Loader />;

  return (
    <Page
      title={year
        ? `Выпуски ${year} г. – скачать «Секрет фирмы» в PDF`
        : 'Архив журнала «Секрет фирмы»: скачать выпуски 2002–2014 гг. в PDF'}
      description={oneLine`Вы можете скачать любой выпуск журнала «Секрет фирмы»
        ${year ? `за ${year} г.` : `${DEFAULT_YEAR_START}–${DEFAULT_YEAR_END} гг.`} в формате PDF.`}
    >
      <ViewportHeightWrapper>
        <PageIndent>
          <ColumnLayout rightColumn={<InnerSideColumn />}>
            <div className='archive'>
              <ListHeader>{`Архив журнала за ${year || DEFAULT_YEAR_END} год`}</ListHeader>
              <NegativeMobile>
                <Years activeYear={year} />
              </NegativeMobile>
              {pageContent}
            </div>
          </ColumnLayout>
        </PageIndent>
        <FooterAds />
      </ViewportHeightWrapper>
    </Page>
  );
};

ArchivePageBody.propTypes = {
  footerContent: PropTypes.array,
  match: PropTypes.shape({
    params: PropTypes.shape({
      year: PropTypes.string,
    }),
  }),
};

function ArchivePage(props) {
  const {
    footerContent,
    ...otherProps
  } = props;

  return (
    <PageBoroda content={footerContent}>
      <ArchivePageBody {...otherProps} />
    </PageBoroda>
  );
}

ArchivePage.propTypes = {
  footerContent: PropTypes.array,
};

const dataProvider = resolve({
  footerContent: footerFetcher(),
});

export default withPageHocs(dataProvider)(ArchivePage);
