import PropTypes from 'prop-types';

import resolve from 'core/resolver/resolve';

import { rawContentFetcher } from 'core/fetchers/searchPage';

import bindProps from 'core/components/bindProps';
import withBreakpoint from 'core/components/breakpoint/withBreakpoint';
import withPageHocs from 'core/components/withPageHocs';

import CoreSearchPage from 'core/components/SearchPage';
import ColumnLayout from 'core/components/ColumnLayout';
import AdWrapper from 'core/components/Ad/AdWrapper';

import { PageIndent } from 'core/components/Wrappers';

import { PageBoroda } from 'site/components/Wrappers';

import InnerSideColumn from 'site/components/InnerSideColumn';
import CardHorizontal from 'site/cards/CardHorizontal';
import { CardVertical1S } from 'site/cards/CardVertical';

import {
  Context,
  SuperFooter,
} from 'site/components/Ads/desktop';

import {
  Listing1,
  ListingSpec,
} from 'site/components/Ads/mobile';

import footerFetcher from 'site/fetchers/footer';


const mobileIndent = 10;
const ListingSpecDivided = bindProps({
  dividerPosition: 'both',
  dividerIndent: mobileIndent,
  addDividerMargins: true,
})(ListingSpec);


/**
 * Результаты поиска на стейджах не будут соответствовать действительности,
 * потому что апишка поиска работает только с продовыми данными.
 * Для стейджей будут выдаваться рандомный список топиков.
 */
function SearchPage(props) {
  const { rawContent, footerContent, isMobile } = props;

  const Card = isMobile ? CardVertical1S : CardHorizontal;

  return (
    <PageBoroda content={footerContent}>
      <PageIndent>
        <ColumnLayout rightColumn={<InnerSideColumn showBoesque={false} />}>
          {/*
            В SearchPage есть собственный AdWrapper.
            Но из-за комопнента PageBoroda ему не хватает веса специфичности.
            Еще почему-то не перезаписывается значение 20, нужно 21.
          */}
          <AdWrapper top={0} bottom={21}>
            <CoreSearchPage
              rawContent={rawContent}
              ad1={Context}
              ad2={SuperFooter}
              ad1Mobile={Listing1}
              ad2Mobile={ListingSpecDivided}
              card={Card}
              interitemSpacing={20}
            />
          </AdWrapper>
        </ColumnLayout>
      </PageIndent>
    </PageBoroda>
  );
}

SearchPage.propTypes = {
  rawContent: PropTypes.object,
  footerContent: PropTypes.array,
  isMobile: PropTypes.bool,
};


const dataProvider = resolve({
  rawContent: props => rawContentFetcher({
    card: props.isMobile ? CardVertical1S : CardHorizontal,
  })(props),
  footerContent: footerFetcher(),
});

export default withBreakpoint(withPageHocs(dataProvider)(SearchPage));
