import PropTypes from 'prop-types';

function Indent(props) {
  const {
    children,
    top: marginTop,
    bottom: marginBottom,
    left: marginLeft,
    right: marginRight,
  } = props;

  return (
    <div
      style={{ marginTop, marginBottom, marginLeft, marginRight }}
    >
      {children}
    </div>
  );
}

Indent.propTypes = {
  top: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  bottom: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  left: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  right: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default Indent;
