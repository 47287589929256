import color from 'core/libs/color';
import baseColors from './colors';
import Logo from 'site/components/Logo';
import bindProps from 'core/components/bindProps';

const LogoDark = bindProps({ type: 'dark' })(Logo);

const colors = {
  ...baseColors,
  primary: '#fff', // Основной цвет, текст
  layout: '#000', // цвет фона body
  content: '#16171D', // цвет фона контентной области
  active1: '#fff', // Доп. цвет, выделение, ссылки
  accent: '#888a0f', // Выделение UGC
  divider: 'rgba(255, 255, 255, 0.3)',
  get placeholder() {
    return color(this.primary).alpha(0.3).string();
  },
  get theme() { // цвет темы, meta theme-color, tile-color, safari-pinned-tab
    return this.active1;
  },
};

const skeleton = {
  background: colors.content,
};

const button = {
  ghost: {
    idle: {
      color: colors.primary,
    },
    hover: {
      color: colors.active1,
    },
  },
};

const bullet = {
  const: {
    size: '8px',
  },

  basic: {
    idle: {
      background: color(colors.primary).alpha(0.6).string(),
    },

    hover: {
      background: color(colors.primary).alpha(0.8).string(),
    },

    active: {
      background: colors.primary,
    },
  },
};

const link = {
  tertiary: {
    visited: {
      color: colors.primary,
    },
  },
};

const shapka = {
  link: link.tertiary,
  dropdown: {
    link: link.tertiary,
  },
};
const comments = {
  const: {
    themeMode: 'dark',
  },
};

const login = {
  popup: {
    theme: 'dark',
    background: colors.primary,
  },
};

const vikontReadMore = {
  block: {
    background: colors.content,
    borderTop: `1px solid ${colors.hint}`,
    borderBottom: `1px solid ${colors.hint}`,
    borderLeft: `1px solid ${colors.hint}`,
    borderRight: `1px solid ${colors.hint}`,
  },
  title: {
    color: colors.hintLight,
  },
};

const mark = {
  color: colors.layout,
};

export default {
  colors,
  icons: {
    logo: LogoDark,
  },
  controls: {
    button,
    bullet,
    link,
    shapka,
    comments,
    skeleton,
    login,
    vikontReadMore,
    mark,
  },
};
