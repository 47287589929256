import PropTypes from 'prop-types';
import cx from 'classnames';

import { withBreakpoint, Desktop, Mobile } from 'core/components/breakpoint';
import { Indent } from 'core/components/Wrappers';
import EmptyWrapper from 'core/components/EmptyWrapper';

import SocializatorSkeleton from 'core/components/Socializator/Skeleton';

import { TopicTextWrap } from 'site/components/Wrappers';

import styles from './index.styl';


function Common({ isMobile }) {
  const WideWrapper = isMobile ? EmptyWrapper : TopicTextWrap;
  return (
    <WideWrapper>
      <Desktop>
        <div className={styles.socializator}>
          <div className={cx('lb-skeleton', styles.subscribe)} />
          <SocializatorSkeleton />
        </div>
      </Desktop>
      <Mobile>
        <div className={cx('lb-skeleton', styles.subscribe)} />
      </Mobile>
      <Indent top={24} bottom={isMobile ? 24 : 36}>
        <div className={cx('lb-skeleton', styles.cover)} />
      </Indent>
      <div className={cx('lb-skeleton', styles.text)} />
    </WideWrapper>
  );
}

Common.propTypes = {
  isMobile: PropTypes.bool,
};

export default withBreakpoint(Common);
