import { Fragment } from 'react';
import PropTypes from 'prop-types';

import modelPropTypes, { imageAttributes } from 'core/utils/prop-types/model';

import coreImage from 'core/components/TopicContent/blocks/image';
import EmptyWrapper from 'core/components/EmptyWrapper';

import CardTopicBg from 'site/components/CardTopicBg';
import {
  Indent,
} from 'site/components/Wrappers';

const inCardIndent = -20;


export default function Image({
  block,
  parentProps,
  ctx,
}) {
  const {
    isMobile,
    topic,
  } = parentProps;

  const isCard = topic.content.attributes.topic_type === 'card';
  const CoreWidgetWrapper = isCard ? CardTopicBg : Fragment;
  const InCardWrapper = isCard && isMobile ? Indent : EmptyWrapper;

  return (
    <CoreWidgetWrapper>
      <InCardWrapper left={inCardIndent} right={inCardIndent}>
        {coreImage(block, parentProps, ctx)}
      </InCardWrapper>
    </CoreWidgetWrapper>
  );
}

Image.propTypes = {
  block: modelPropTypes(imageAttributes).isRequired,
  parentProps: PropTypes.object,
  ctx: PropTypes.object,
};
