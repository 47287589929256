import PropTypes from 'prop-types';

import { NegativeMobile } from 'site/components/Wrappers';

import TopicHeaderImage from './TopicHeaderImage';
import TopicHeaderGallery from './TopicHeaderGallery';
import TopicHeaderVideo from './TopicHeaderVideo';

const topicTypeMap = {
  news: TopicHeaderImage,
  article: TopicHeaderImage,
  gallery: TopicHeaderGallery,
  video: TopicHeaderVideo,
};

export default function TopicHeaderMedia({ content }) {
  const topicType = content.attributes.topic_type;
  const HeaderContent = topicTypeMap[topicType] || TopicHeaderImage;

  return (
    <NegativeMobile>
      <HeaderContent content={content} />
    </NegativeMobile>
  );
}

TopicHeaderMedia.propTypes = {
  content: PropTypes.object.isRequired,
};
