import PropTypes from 'prop-types';
import cx from 'classnames';

import bindProps from 'core/components/bindProps';

import resolveRelationships from 'core/utils/relationships';
import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';

import skip from 'core/resolver/skip';
import withTheme from 'core/components/theme';

import SmartImage from 'core/components/SmartImage';
import Link from 'core/components/Link';

import CardInfoLine from 'site/components/CardInfoLine';
import HoverFrame from 'site/components/HoverFrame';

import IconVideo from 'site/icons/Video';
import IconCards from 'site/icons/Cards';

import UgcLabel from 'site/components/UgcLabel';

import mapTypes from './types';

import styles from './index.styl';


const requiredPayloadImports = [
  'image',
  'rubric',
];

const requiredPayloadFields = [
  'link',
  'headline',
  'list_headline',
  'alternative_headline',
  'topic_type',
  'published_at',
];

const relationships = resolveRelationships(requiredPayloadImports, null, {
  image: {
    versions: {},
  },
});

const iconsMap = {
  video: IconVideo,
  card: IconCards,
};

const sizes = {
  m: {
    imageMaxWidth: 270,
  },
  l: {
    imageMaxWidth: 435,
  },
};


function CardHorizontal(props) {
  const {
    content,
    type,
    size,
    theme,
  } = props;

  if (!content) return null;

  const {
    link,
    headline,
    topic_type: topicType,
    published_at: publishedAt,
    list_headline: listHeadline,
    alternative_headline: altHeadline,
  } = content.attributes;

  const {
    image: {
      versions: {
        original: originalCover,
        thumbnail: previewCover,
      },
    },
    rubric,
  } = relationships(content);

  const {
    accented,
    imageAtRight,
  } = mapTypes[type] || {};

  const {
    imageMaxWidth,
  } = sizes[size] || {};

  const {
    link_attrs: linkAttrs,
    remote_image: remoteImage,
  } = content.extra || {};

  const isUgc = topicType === 'ugc';
  const isSpecproject = topicType === 'specproject';
  const showInfoLine = !!(isUgc || publishedAt || rubric);

  const Icon = iconsMap[topicType];

  return (
    <Link
      to={link}
      className={styles.card}
      type='secondary'
      {...linkAttrs && {
        innerRef: node => {
          if (node) {
            linkAttrs.forEach(({ name, value }) => node.setAttribute(name, value));
          }
        },
      }}
      {...isSpecproject && { target: '_blank' }}
    >
      <style jsx>{`
        .${styles.img}
          &:after
            background ${theme.colors.yellow}

        .${styles.body}
          border-color ${theme.colors.divider}
          color ${theme.colors.primary}
          .${styles._accented} &
            background ${theme.colors.yellow}
            border-color ${theme.colors.yellow}
            color ${theme.colors.text}

        .${styles.headline}
          font-family ${theme.fonts.display}

        .${styles.altHeadline}
          font-family ${theme.fonts.text}
      `}</style>
      <HoverFrame>
        <div
          className={cx(
            styles.wrapper,
            styles[`_${topicType}`],
            styles[`_size_${size}`],
            accented && styles._accented,
            imageAtRight && styles._imageAtRight,
          )}
        >
          {(originalCover || remoteImage) && (
            <div className={styles.img}>
              <SmartImage
                {...originalCover && {
                  src: originalCover,
                  previewSrc: previewCover,
                }}
                {...remoteImage && { url: remoteImage }}
                maxWidth={imageMaxWidth}
                aspectRatio={1.5}
              />
              {Icon && (
                <div className={styles.iconWrapper}>
                  <Icon
                    width={19}
                    height={19}
                  />
                </div>
              )}
              {isUgc && <UgcLabel className={styles.ugcLabel} />}
            </div>
          )}
          <div className={styles.body}>
            {(listHeadline || headline) && (
              <div className={styles.headline}>
                {listHeadline || headline}
              </div>
            )}
            {altHeadline && (
              <div className={styles.altHeadline}>
                {altHeadline}
              </div>
            )}
            {showInfoLine && (
              <div className={styles.cardInfo}>
                <CardInfoLine
                  rubric={rubric}
                  publishedAt={publishedAt}
                  {...accented && { color: theme.colors.hint }}
                />
              </div>
            )}
          </div>
        </div>
      </HoverFrame>
    </Link>
  );
}

CardHorizontal.propTypes = {
  /** Контент топика */
  content: modelPropTypes(topicAttributes),
  /** Тип карточки */
  type: PropTypes.oneOf([0, 1, 2]),
  /** Размер карточки */
  size: PropTypes.oneOf(['m', 'l']),
  /** @ignore */
  theme: PropTypes.object,
};

CardHorizontal.defaultProps = {
  type: 0,
  size: 'm',
};

const Card = skip(withTheme(CardHorizontal));

Card.displayName = 'CardHorizontal';
Card.requiredPayloadFields = requiredPayloadFields;
Card.requiredPayloadImports = requiredPayloadImports;

export { Card as CardHorizontalM };
export { Card as CardHorizontal0M };
export const CardHorizontal1L = bindProps({ type: 1, size: 'l' })(Card);
export const CardHorizontal2L = bindProps({ type: 2, size: 'l' })(Card);
export const CardHorizontalL = bindProps({ size: 'l' })(Card);

export default Card;

export { CardHorizontal as StorybookComponent };
