import PropTypes from 'prop-types';

import { compose } from 'core/libs/recompose';

import resolve from 'core/resolver/resolve';
import topicProvider from 'core/resolver/data/topic';

import Topic from 'core/components/Topic';
import LeaderBoardCore from 'core/components/GameCompare/LeaderBoard';
import Page from 'core/components/Page';

import withTheme from 'core/components/theme';
import withBreakpoint from 'core/components/breakpoint/withBreakpoint';
import withPageHocs from 'core/components/withPageHocs';
import resolveRelationships from 'core/utils/relationships';
import ColumnLayout from 'core/components/ColumnLayout';
import { PageIndent } from 'core/components/Wrappers';

import InnerSideColumn from 'site/components/InnerSideColumn';
import {
  Indent,
  PageBoroda,
} from 'site/components/Wrappers';

import footerFetcher from 'site/fetchers/footer';
import TopicHeader from 'site/pages/topic/TopicHeader';


const relationships = resolveRelationships(['content'], {});

function LeaderBoard({ content, footerContent, isMobile, theme }) {
  return (
    <PageBoroda content={footerContent}>
      <Page>
        <PageIndent>
          <style jsx>{`
            .announce
              font 18px/27px ${theme.fonts.text}
              color ${theme.colors.primary}
              :global(.mobile) &
                font 16px/22px ${theme.fonts.text}
          `}</style>
          <Topic content={content}>
            {topic => {
              const {
                content: {
                  widgets,
                },
              } = relationships(topic);

              if (widgets.length === 0) return null;

              const {
                final_announce: finalAnnounce,
              } = widgets[0].attributes;

              return (
                <ColumnLayout rightColumn={<InnerSideColumn />}>
                  <TopicHeader content={topic} isGameCompare />
                  <Indent top={20} bottom={isMobile ? 35 : 25} >
                    <div className='announce'>{finalAnnounce}</div>
                  </Indent>
                  <LeaderBoardCore content={topic} widget={widgets[0]} />
                </ColumnLayout>
              );
            }}
          </Topic>
        </PageIndent>
      </Page>
    </PageBoroda>
  );
}

LeaderBoard.propTypes = {
  content: PropTypes.object,
  footerContent: PropTypes.array,
  isMobile: PropTypes.bool,
  theme: PropTypes.object,
};

const additionalDataProvider = resolve({
  footerContent: footerFetcher(),
});

const composedProvider = compose(topicProvider, additionalDataProvider);

export default withPageHocs(composedProvider)(withBreakpoint(withTheme(LeaderBoard)));
