import PropTypes from 'prop-types';
import Image from 'core/components/Image';
import CaptionCredits from 'core/components/CaptionCredits';
import { withBreakpoint } from 'core/components/breakpoint';
import resolveRelationships from 'core/utils/relationships';

import {
  LAYOUT_MAX_WIDTH,
  LAYOUT_MAX_WIDTH_MOBILE,
  CONTENT_AREA,
} from 'site/constants';

import styles from './topicHeaderImage.styl';


const relationships = resolveRelationships(
  ['image'],
  {},
  { image: { versions: {} } },
);


function TopicHeaderImage({ content, isDesktop }) {
  const {
    image: {
      versions,
      credits_url: creditsUrl,
      caption,
      credits,
    },
  } = relationships(content);

  let maxWidth = LAYOUT_MAX_WIDTH_MOBILE;
  let maxHeight = 400;
  const { headline } = content.attributes;
  const altAndTitle = caption || headline;

  if (isDesktop) {
    const {
      attributes: {
        is_premium: isPremium,
      },
    } = content;

    maxWidth = isPremium ? LAYOUT_MAX_WIDTH : CONTENT_AREA;
    maxHeight = isPremium ? 650 : 600;
  }

  return (
    <>
      <div className={styles.image}>
        <Image
          versions={versions}
          widthFromSrc
          maxWidth={maxWidth}
          maxHeight={maxHeight}
          placement='contain'
          alt={altAndTitle}
          title={altAndTitle}
        />
      </div>
      <div className={styles.caption}>
        <CaptionCredits
          credits={credits}
          creditsUrl={creditsUrl}
        />
      </div>
    </>
  );
}

TopicHeaderImage.propTypes = {
  content: PropTypes.object,
  isDesktop: PropTypes.bool,
};

export default withBreakpoint(TopicHeaderImage);
