import { Component } from 'react';
import PropTypes from 'prop-types';

import { debounce } from 'core/libs/lodash';

import queryString from 'core/libs/query-string';
import Position from 'core/components/GridPosition';
import H2 from 'core/components/H2';

import resolve from 'core/resolver/resolve';

import { getPage } from 'core/utils/url-helper';
import { denormalizeData, filterRequiredParams } from 'core/utils/api';
import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';

import InnerSideColumn from 'site/components/InnerSideColumn';
import Divider from 'core/components/Divider';
import { Block, Section } from 'core/components/Grid';
import SearchInput from 'core/components/SearchInput';
import ListPage from 'core/components/ListPage';
import ColumnLayout from 'core/components/ColumnLayout';
import withPageHocs from 'core/components/withPageHocs';
import { PageIndent, Indent } from 'core/components/Wrappers';

import EnciklopediyaDescription from 'site/components/EnciklopediyaDescription';
import { PageBoroda } from 'site/components/Wrappers';

import CardEnciklopedy from 'site/cards/CardEnciklopedy';
import CardSmall  from 'site/cards/CardSmall';

import footerFetcher from 'site/fetchers/footer';

import { ENCIKLOPEDIYA_PAGE_LIMIT } from 'site/constants';

import Topics from './Topics';
import Tags from './Tags';


const title = 'Объясняем простыми словами сложные вещи — все статьи и новости';
const description = 'Энциклопедия российского интернет-журнала об экономике, финансах и высоких технологиях. Секрет фирмы объясняет простыми словами сложные термины, рассказывает про ключевые фигуры в политике и мире бизнеса';

const include = filterRequiredParams([CardEnciklopedy], 'include', ['tags']);

class EnciklopediyaPage extends Component {
  static getDerivedStateFromProps(props, state) {
    if (!state.value) {
      return {
        ...state,
        contentData: props.topicsData,
      };
    }

    return null;
  }

  constructor(props) {
    super(props);

    this.state = {
      contentData: this.props.topicsData,
      value: '',
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      location: {
        search,
        pathname,
      },
      history,
    } = this.props;
    const {
      value,
    } = this.state;
    const {
      value: prevValue,
    } = prevState;

    if (!!value && value !== prevValue) {
      if (!!search) {
        history.replace(
          pathname,
          { infinity: true }
        );
      }
      this.getSearchTopics();
      return;
    }
  }

  handleSearchInput = ({ target: { value } }) => {
    this.setState({ value });
  };

  getSearchTopics = debounce(() => {
    const { bebopApi } = this.context;
    const {
      location: {
        search,
      },
    } = this.props;
    const {
      value,
    } = this.state;

    const offset = (getPage(search) - 1) * ENCIKLOPEDIYA_PAGE_LIMIT;

    bebopApi
      .getSearch({
        query: value,
        rubric: 'enciklopediya',
        limit: ENCIKLOPEDIYA_PAGE_LIMIT,
        offset,
        title_only: 1,
        include,
      })
      .then(searchData => this.setState({
        contentData: {
          ...searchData,
          meta: {
            filtered_count: searchData?.meta?.search_results.total_found,
          },
        },
      }))
      .catch(() => this.setState({
        contentData: {
          meta: { filtered_count: 0 },
        },
      }));
  }, 1000);

  render() {
    const {
      news,
      footerContent,
    } = this.props;

    const {
      contentData,
      value,
    } = this.state;

    const {
      filtered_count: filteredCount,
    } = contentData?.meta || {};

    return (
      <PageBoroda content={footerContent}>
        <PageIndent>
          <ColumnLayout rightColumn={<InnerSideColumn hideRnet />}>
            <EnciklopediyaDescription />
            <Indent bottom={40} />
            <Tags disabled={!!value} />
            <Divider />
            <Indent bottom={40} />
            <Section>
              <Block desktop={2} mobile={0} />
              <Block desktop={9} mobile={12} >
                <SearchInput
                  placeholder='Поиск термина'
                  onInput={this.handleSearchInput}
                  value={value}
                />
              </Block>
              <Block />
            </Section>
            <Indent bottom={40} />
            {filteredCount > 0 ? (
              <ListPage
                rawData={contentData}
                title={title}
                description={description}
                limit={ENCIKLOPEDIYA_PAGE_LIMIT}
              >
                {({ content }) => <Topics topics={content} news={news} />}
              </ListPage>
            ) : (
              <Position center><H2>Материалов не найдено</H2></Position>
            )}
          </ColumnLayout>
        </PageIndent>
      </PageBoroda>
    );
  }
}

EnciklopediyaPage.contextTypes = {
  bebopApi: PropTypes.object,
};

EnciklopediyaPage.propTypes = {
  topicsData: PropTypes.shape({
    topics: PropTypes.arrayOf(modelPropTypes(topicAttributes)),
    meta: PropTypes.object,
  }).isRequired,
  news: PropTypes.arrayOf(modelPropTypes(topicAttributes)),
  footerContent: PropTypes.array,
  location: PropTypes.object,
  history: PropTypes.object,
};


const dataProvider = resolve({
  topicsData(props) {
    const {
      bebopApi,
      topicsData,
      location: { search },
      renderError,
    } = props;

    const offset = (getPage(search) - 1) * ENCIKLOPEDIYA_PAGE_LIMIT;
    const queryFilters = queryString.parse(search);
    const searchTag = queryFilters.search || '';

    return topicsData || bebopApi
      .getTopics({
        limit: ENCIKLOPEDIYA_PAGE_LIMIT,
        include,
        offset,
        fields: filterRequiredParams([CardEnciklopedy], 'fields'),
        rubric: 'enciklopediya',
        tag: searchTag,
        with_filtered_count: 1,
        sort: 'list_headline,headline',
      })
      .catch(renderError);
  },

  news({ news, consoleError, bebopApi }) {
    return news || bebopApi
      .getTopics({
        fields: filterRequiredParams([CardSmall], 'fields'),
        rubric: 'practice,zhizn',
        limit: 7,
      })
      .then(denormalizeData)
      .catch(consoleError('news on enciklopedia page', []));
  },

  footerContent: footerFetcher(),
});

export default withPageHocs(dataProvider)(EnciklopediyaPage);
