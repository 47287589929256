export default {
  0: {
    imageRatio: 3 / 2,
  },
  1: {
    imageRatio: 3 / 2,
    imageUnderlined: true,
  },
  2: {
    accented: true,
    imageRatio: 1,
  },
};
