import PropTypes from 'prop-types';

import withTheme from 'core/components/theme';

import Indent from './Indent';


function PageIndent(props) {
  const {
    children,
    theme: {
      layout: {
        indents,
      },
    },
  } = props;

  return (
    <Indent {...indents}>
      {children}
    </Indent>
  );
}

PageIndent.propTypes = {
  theme: PropTypes.object,
};

export default withTheme(PageIndent);
